import { Component, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { CommonService } from 'src/app/core/Services/common.service';
import { Params_Send_Mail } from 'src/app/core/Services/proxy.service';
import { WebsiteManagementService } from 'src/app/core/Services/website-management.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  @Input() data: any;
  @ViewChild('contactForm') contactForm: NgForm;
  @Output() scrollEvent = new EventEmitter<string>();

  name: string;
  email: string;
  subject: string;
  message: string;
  loading = false;
  mobileView = false;

  @HostListener('window:resize', ['$event'])

  onResize(): void {
    this.mobileView = window.innerWidth <= 600;
  }
  constructor(
    private CmSvc: CommonService,
    private websiteMgmt: WebsiteManagementService,
  ) { }

  ngOnInit(): void {
    this.mobileView = window.innerWidth <= 600;
  }

  sendMail(): void {
    const oParams_Send_Mail = new Params_Send_Mail();
    if (this.name && this.message && this.email) {
      oParams_Send_Mail.SENDER_NAME = this.name;
      oParams_Send_Mail.SENDER_MAIL = this.email;
      oParams_Send_Mail.SUBJECT = this.subject;
      oParams_Send_Mail.SENDER_MESSAGE = this.message;
      this.contactForm.form.disable();
      this.websiteMgmt.Send_Mail(oParams_Send_Mail).then(result => {
        if (result) {
          const btn = document.getElementById('closeButton');
          if (btn) {
            btn.click();
          }
          this.contactForm.form.enable();
          this.contactForm.form.reset();
        }
      });
    }
    else {
      this.CmSvc.ShowMessage('Missing data');
    }
  }

  emitDestination(dest): void {
    this.scrollEvent.emit(dest);
  }
}
