import { Injectable } from '@angular/core';

@Injectable()
export class CommonService {
    Ticket = '';

    // oUser_Details: User_Details;

    // oGeneral_settings: General_settings;
    // oList_Currency: Currency[] = [];
    // oList_Setup_category: Setup_category[] = [];

    // APIUrl = 'http://localhost/TektranApi';
    // AssetsUrl = 'http://localhost/TektranApi/Files';

    APIUrl = 'https://api.tektran.com';
    AssetsUrl = 'https://api.tektran.com/Files';

    constructor() { }

    ShowMessage(message: string, d: number = 2000): void {
        console.log(message, '', { duration: d });
    }
    formatDate(date): any {
        const d = new Date(date);
        let month = '' + (d.getMonth() + 1);
        let day = '' + d.getDate();
        const year = d.getFullYear();

        if (month.length < 2) {
            month = '0' + month;
        }
        if (day.length < 2) {
            day = '0' + day;
        }
        return [year, month, day].join('-');
    }
    isNumeric(str) {
        if (typeof str != "string") {
            return false;
        }
        return !isNaN(parseInt(str)) && !isNaN(parseFloat(str));
    }
    getRoute(fullRoute) {
        let route = fullRoute.split('/').pop().toUpperCase();
        if (this.isNumeric(route)) {
            route = '';
        }
        return route;
    }
    toTitleCase(str) {
        return str.replace(
            /\w\S*/g,
            function (txt) {
                return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
            }
        );
    }
    Get_Enum_String_Keys_List(EnumVal) {
        return Object.keys(EnumVal).filter(x => isNaN(parseInt(x)));
    }
    Handle_Success(msg = "Success!", timeout = 3000): void {
        if ((msg != null) && (msg !== '')) {
            this.ShowMessage(msg, timeout);
        }
    }
    Handle_Exception(msg: string): void {
        if ((msg != null) && (msg !== '')) {
            // console.log(msg);
            this.ShowMessage(msg, 3000);
        }
    }
    Handle_Stack_Trace(msg: string): void {
        if ((msg != null) && (msg !== '')) {
            // console.log(msg);
        }
    }
    No_Changes_Message(): void {
        this.ShowMessage("No changes were made", 3000);
    }
    Objects_Equal(obj1, obj2): boolean {
        return JSON.stringify(obj1) == JSON.stringify(obj2);
    }
    Objects_Different(obj1, obj2): boolean {
        return JSON.stringify(obj1) != JSON.stringify(obj2);
    }
    Generate_Unique_String(length: number = 10) {
        var result = '';
        var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for (var i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() *
                charactersLength));
        }
        return result;
    }
    Is_String_Empty(str: string) {
        return !str || str == '';
    }
    Generate_Random_String(stringLength = 12, includeAlpha = true, includeCalpha = true, includeNum = true, includeSpecials = true): string {
        const numberChars = "0123456789";
        const upperChars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
        const lowerChars = "abcdefghijklmnopqrstuvwxyz";
        const specials = ',.!@#$%^&*';
        const allChars = (includeNum ? numberChars : '') + (includeCalpha ? upperChars : '') + (includeAlpha ? lowerChars : '') + (includeSpecials ? specials : '');
        let randPasswordArray = Array(stringLength);
        randPasswordArray[0] = numberChars;
        randPasswordArray[1] = upperChars;
        randPasswordArray[2] = lowerChars;
        randPasswordArray = randPasswordArray.fill(allChars, 3);
        return this.Shuffle_Array(randPasswordArray.map(function (x) { return x[Math.floor(Math.random() * x.length)] })).join('');
    }
    Shuffle_Array(array) {
        for (var i = array.length - 1; i > 0; i--) {
            var j = Math.floor(Math.random() * (i + 1));
            var temp = array[i];
            array[i] = array[j];
            array[j] = temp;
        }
        return array;
    }
}
