import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { CommonService } from './common.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable()
export class Proxy {
    constructor(private api: HttpClient, private CmSvc: CommonService) { }

    Delete_User_Custom(i_Params_Delete_User_Custom: Params_Delete_User_Custom): Observable<boolean> {
        const url = this.CmSvc.APIUrl + '/api/data/Delete_User_Custom';
        const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Ticket': this.CmSvc.Ticket });
        const options = { headers: headers };
        return this.api.post<Result_Delete_User_Custom>(url, JSON.stringify(i_Params_Delete_User_Custom), options)
            .pipe(map(response => {
                this.CmSvc.Handle_Stack_Trace(response.Stack_Trace);
                this.CmSvc.Handle_Exception(response.Exception_Message);
                return response.i_Result;
            })
        );
    }
    Get_Project_Details_Dashboard(i_Params_Get_Project_Details_Dashboard: Params_Get_Project_Details_Dashboard): Observable<Project_Details_Dashboard> {
        const url = this.CmSvc.APIUrl + '/api/data/Get_Project_Details_Dashboard';
        const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Ticket': this.CmSvc.Ticket });
        const options = { headers: headers };
        return this.api.post<Result_Get_Project_Details_Dashboard>(url, JSON.stringify(i_Params_Get_Project_Details_Dashboard), options)
            .pipe(map(response => {
                this.CmSvc.Handle_Stack_Trace(response.Stack_Trace);
                this.CmSvc.Handle_Exception(response.Exception_Message);
                return response.i_Result;
            })
        );
    }
    Delete_Project_Component_Category_Custom(i_Params_Delete_Project_Component_Category_Custom: Params_Delete_Project_Component_Category_Custom): Observable<boolean> {
        const url = this.CmSvc.APIUrl + '/api/data/Delete_Project_Component_Category_Custom';
        const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Ticket': this.CmSvc.Ticket });
        const options = { headers: headers };
        return this.api.post<Result_Delete_Project_Component_Category_Custom>(url, JSON.stringify(i_Params_Delete_Project_Component_Category_Custom), options)
            .pipe(map(response => {
                this.CmSvc.Handle_Stack_Trace(response.Stack_Trace);
                this.CmSvc.Handle_Exception(response.Exception_Message);
                return response.i_Result;
            })
        );
    }
    Delete_Project_Custom(i_Params_Delete_Project_Custom: Params_Delete_Project_Custom): Observable<boolean> {
        const url = this.CmSvc.APIUrl + '/api/data/Delete_Project_Custom';
        const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Ticket': this.CmSvc.Ticket });
        const options = { headers: headers };
        return this.api.post<Result_Delete_Project_Custom>(url, JSON.stringify(i_Params_Delete_Project_Custom), options)
            .pipe(map(response => {
                this.CmSvc.Handle_Stack_Trace(response.Stack_Trace);
                this.CmSvc.Handle_Exception(response.Exception_Message);
                return response.i_Result;
            })
        );
    }
    Edit_Home_info(i_Home_info: Home_info): Observable<Home_info> {
        const url = this.CmSvc.APIUrl + '/api/data/Edit_Home_info';
        const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Ticket': this.CmSvc.Ticket });
        const options = { headers: headers };
        return this.api.post<Result_Edit_Home_info>(url, JSON.stringify(i_Home_info), options)
            .pipe(map(response => {
                this.CmSvc.Handle_Stack_Trace(response.Stack_Trace);
                this.CmSvc.Handle_Exception(response.Exception_Message);
                return response.Home_info;
            })
        );
    }
    Edit_Project_entry(i_Project_entry: Project_entry): Observable<Project_entry> {
        const url = this.CmSvc.APIUrl + '/api/data/Edit_Project_entry';
        const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Ticket': this.CmSvc.Ticket });
        const options = { headers: headers };
        return this.api.post<Result_Edit_Project_entry>(url, JSON.stringify(i_Project_entry), options)
            .pipe(map(response => {
                this.CmSvc.Handle_Stack_Trace(response.Stack_Trace);
                this.CmSvc.Handle_Exception(response.Exception_Message);
                return response.Project_entry;
            })
        );
    }
    Delete_Uploaded_file_By_REL_ENTITY_REL_KEY_REL_FIELD(i_Params_Delete_Uploaded_file_By_REL_ENTITY_REL_KEY_REL_FIELD: Params_Delete_Uploaded_file_By_REL_ENTITY_REL_KEY_REL_FIELD): Observable<string> {
        const url = this.CmSvc.APIUrl + '/api/data/Delete_Uploaded_file_By_REL_ENTITY_REL_KEY_REL_FIELD';
        const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Ticket': this.CmSvc.Ticket });
        const options = { headers: headers };
        return this.api.post<Result_Delete_Uploaded_file_By_REL_ENTITY_REL_KEY_REL_FIELD>(url, JSON.stringify(i_Params_Delete_Uploaded_file_By_REL_ENTITY_REL_KEY_REL_FIELD), options)
            .pipe(map(response => {
                this.CmSvc.Handle_Stack_Trace(response.Stack_Trace);
                this.CmSvc.Handle_Exception(response.Exception_Message);
                return response.Exception_Message;
            })
        );
    }
    Delete_Mail(i_Params_Delete_Mail: Params_Delete_Mail): Observable<string> {
        const url = this.CmSvc.APIUrl + '/api/data/Delete_Mail';
        const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Ticket': this.CmSvc.Ticket });
        const options = { headers: headers };
        return this.api.post<Result_Delete_Mail>(url, JSON.stringify(i_Params_Delete_Mail), options)
            .pipe(map(response => {
                this.CmSvc.Handle_Stack_Trace(response.Stack_Trace);
                this.CmSvc.Handle_Exception(response.Exception_Message);
                return response.Exception_Message;
            })
        );
    }
    Edit_Project(i_Project: Project): Observable<Project> {
        const url = this.CmSvc.APIUrl + '/api/data/Edit_Project';
        const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Ticket': this.CmSvc.Ticket });
        const options = { headers: headers };
        return this.api.post<Result_Edit_Project>(url, JSON.stringify(i_Project), options)
            .pipe(map(response => {
                this.CmSvc.Handle_Stack_Trace(response.Stack_Trace);
                this.CmSvc.Handle_Exception(response.Exception_Message);
                return response.Project;
            })
        );
    }
    Get_Project_List(i_Params_Get_Project_List: Params_Get_Project_List): Observable<Project[]> {
        const url = this.CmSvc.APIUrl + '/api/data/Get_Project_List';
        const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Ticket': this.CmSvc.Ticket });
        const options = { headers: headers };
        return this.api.post<Result_Get_Project_List>(url, JSON.stringify(i_Params_Get_Project_List), options)
            .pipe(map(response => {
                this.CmSvc.Handle_Stack_Trace(response.Stack_Trace);
                this.CmSvc.Handle_Exception(response.Exception_Message);
                return response.i_Result;
            })
        );
    }
    Edit_Service(i_Service: Service): Observable<Service> {
        const url = this.CmSvc.APIUrl + '/api/data/Edit_Service';
        const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Ticket': this.CmSvc.Ticket });
        const options = { headers: headers };
        return this.api.post<Result_Edit_Service>(url, JSON.stringify(i_Service), options)
            .pipe(map(response => {
                this.CmSvc.Handle_Stack_Trace(response.Stack_Trace);
                this.CmSvc.Handle_Exception(response.Exception_Message);
                return response.Service;
            })
        );
    }
    Get_Project_entry_List(i_Params_Get_Project_entry_List: Params_Get_Project_entry_List): Observable<Project_Entry_Custom> {
        const url = this.CmSvc.APIUrl + '/api/data/Get_Project_entry_List';
        const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Ticket': this.CmSvc.Ticket });
        const options = { headers: headers };
        return this.api.post<Result_Get_Project_entry_List>(url, JSON.stringify(i_Params_Get_Project_entry_List), options)
            .pipe(map(response => {
                this.CmSvc.Handle_Stack_Trace(response.Stack_Trace);
                this.CmSvc.Handle_Exception(response.Exception_Message);
                return response.i_Result;
            })
        );
    }
    Change_User_Password(i_Params_Change_User_Password: Params_Change_User_Password): Observable<boolean> {
        const url = this.CmSvc.APIUrl + '/api/data/Change_User_Password';
        const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Ticket': this.CmSvc.Ticket });
        const options = { headers: headers };
        return this.api.post<Result_Change_User_Password>(url, JSON.stringify(i_Params_Change_User_Password), options)
            .pipe(map(response => {
                this.CmSvc.Handle_Stack_Trace(response.Stack_Trace);
                this.CmSvc.Handle_Exception(response.Exception_Message);
                return response.i_Result;
            })
        );
    }
    Edit_Our_mission(i_Our_mission: Our_mission): Observable<Our_mission> {
        const url = this.CmSvc.APIUrl + '/api/data/Edit_Our_mission';
        const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Ticket': this.CmSvc.Ticket });
        const options = { headers: headers };
        return this.api.post<Result_Edit_Our_mission>(url, JSON.stringify(i_Our_mission), options)
            .pipe(map(response => {
                this.CmSvc.Handle_Stack_Trace(response.Stack_Trace);
                this.CmSvc.Handle_Exception(response.Exception_Message);
                return response.Our_mission;
            })
        );
    }
    Authenticate_User(i_Params_Authenticate_User: Params_Authenticate_User): Observable<User_Details> {
        const url = this.CmSvc.APIUrl + '/api/data/Authenticate_User';
        const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Ticket': this.CmSvc.Ticket });
        const options = { headers: headers };
        return this.api.post<Result_Authenticate_User>(url, JSON.stringify(i_Params_Authenticate_User), options)
            .pipe(map(response => {
                this.CmSvc.Handle_Stack_Trace(response.Stack_Trace);
                this.CmSvc.Handle_Exception(response.Exception_Message);
                return response.i_Result;
            })
        );
    }
    Edit_Facility(i_Facility: Facility): Observable<Facility> {
        const url = this.CmSvc.APIUrl + '/api/data/Edit_Facility';
        const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Ticket': this.CmSvc.Ticket });
        const options = { headers: headers };
        return this.api.post<Result_Edit_Facility>(url, JSON.stringify(i_Facility), options)
            .pipe(map(response => {
                this.CmSvc.Handle_Stack_Trace(response.Stack_Trace);
                this.CmSvc.Handle_Exception(response.Exception_Message);
                return response.Facility;
            })
        );
    }
    Get_Mail(i_Params_Get_Mail: Params_Get_Mail): Observable<Custom_Mail> {
        const url = this.CmSvc.APIUrl + '/api/data/Get_Mail';
        const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Ticket': this.CmSvc.Ticket });
        const options = { headers: headers };
        return this.api.post<Result_Get_Mail>(url, JSON.stringify(i_Params_Get_Mail), options)
            .pipe(map(response => {
                this.CmSvc.Handle_Stack_Trace(response.Stack_Trace);
                this.CmSvc.Handle_Exception(response.Exception_Message);
                return response.i_Result;
            })
        );
    }
    Edit_Cost_comp(i_Cost_comp: Cost_comp): Observable<Cost_comp> {
        const url = this.CmSvc.APIUrl + '/api/data/Edit_Cost_comp';
        const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Ticket': this.CmSvc.Ticket });
        const options = { headers: headers };
        return this.api.post<Result_Edit_Cost_comp>(url, JSON.stringify(i_Cost_comp), options)
            .pipe(map(response => {
                this.CmSvc.Handle_Stack_Trace(response.Stack_Trace);
                this.CmSvc.Handle_Exception(response.Exception_Message);
                return response.Cost_comp;
            })
        );
    }
    Edit_Page(i_Page: Page): Observable<Page> {
        const url = this.CmSvc.APIUrl + '/api/data/Edit_Page';
        const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Ticket': this.CmSvc.Ticket });
        const options = { headers: headers };
        return this.api.post<Result_Edit_Page>(url, JSON.stringify(i_Page), options)
            .pipe(map(response => {
                this.CmSvc.Handle_Stack_Trace(response.Stack_Trace);
                this.CmSvc.Handle_Exception(response.Exception_Message);
                return response.Page;
            })
        );
    }
    Delete_Project_Component_Custom(i_Params_Delete_Project_Component_Custom: Params_Delete_Project_Component_Custom): Observable<boolean> {
        const url = this.CmSvc.APIUrl + '/api/data/Delete_Project_Component_Custom';
        const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Ticket': this.CmSvc.Ticket });
        const options = { headers: headers };
        return this.api.post<Result_Delete_Project_Component_Custom>(url, JSON.stringify(i_Params_Delete_Project_Component_Custom), options)
            .pipe(map(response => {
                this.CmSvc.Handle_Stack_Trace(response.Stack_Trace);
                this.CmSvc.Handle_Exception(response.Exception_Message);
                return response.i_Result;
            })
        );
    }
    Edit_Project_component_category(i_Project_component_category: Project_component_category): Observable<Project_component_category> {
        const url = this.CmSvc.APIUrl + '/api/data/Edit_Project_component_category';
        const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Ticket': this.CmSvc.Ticket });
        const options = { headers: headers };
        return this.api.post<Result_Edit_Project_component_category>(url, JSON.stringify(i_Project_component_category), options)
            .pipe(map(response => {
                this.CmSvc.Handle_Stack_Trace(response.Stack_Trace);
                this.CmSvc.Handle_Exception(response.Exception_Message);
                return response.Project_component_category;
            })
        );
    }
    Get_User_By_OWNER_ID_IS_DELETED_Adv(i_Params_Get_User_By_OWNER_ID_IS_DELETED: Params_Get_User_By_OWNER_ID_IS_DELETED): Observable<User[]> {
        const url = this.CmSvc.APIUrl + '/api/data/Get_User_By_OWNER_ID_IS_DELETED_Adv?OWNER_ID=' + i_Params_Get_User_By_OWNER_ID_IS_DELETED.OWNER_ID + '&IS_DELETED=' + i_Params_Get_User_By_OWNER_ID_IS_DELETED.IS_DELETED;
        const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Ticket': this.CmSvc.Ticket });
        const options = { headers: headers };
        return this.api.get<Result_Get_User_By_OWNER_ID_IS_DELETED_Adv>(url, options)
            .pipe(map(response => {
                this.CmSvc.Handle_Stack_Trace(response.Stack_Trace);
                this.CmSvc.Handle_Exception(response.Exception_Message);
                return response.i_Result;
            })
        );
    }
    Get_Project_Details(i_Params_Get_Project_Details: Params_Get_Project_Details): Observable<Project_Details> {
        const url = this.CmSvc.APIUrl + '/api/data/Get_Project_Details';
        const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Ticket': this.CmSvc.Ticket });
        const options = { headers: headers };
        return this.api.post<Result_Get_Project_Details>(url, JSON.stringify(i_Params_Get_Project_Details), options)
            .pipe(map(response => {
                this.CmSvc.Handle_Stack_Trace(response.Stack_Trace);
                this.CmSvc.Handle_Exception(response.Exception_Message);
                return response.i_Result;
            })
        );
    }
    Get_App_Settings(): Observable<App_Settings> {
        const url = this.CmSvc.APIUrl + '/api/data/Get_App_Settings';
        const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Ticket': this.CmSvc.Ticket });
        const options = { headers: headers };
        return this.api.post<Result_Get_App_Settings>(url, {}, options)
            .pipe(map(response => {
                this.CmSvc.Handle_Stack_Trace(response.Stack_Trace);
                this.CmSvc.Handle_Exception(response.Exception_Message);
                return response.i_Result;
            })
        );
    }
    Edit_Division(i_Division: Division): Observable<Division> {
        const url = this.CmSvc.APIUrl + '/api/data/Edit_Division';
        const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Ticket': this.CmSvc.Ticket });
        const options = { headers: headers };
        return this.api.post<Result_Edit_Division>(url, JSON.stringify(i_Division), options)
            .pipe(map(response => {
                this.CmSvc.Handle_Stack_Trace(response.Stack_Trace);
                this.CmSvc.Handle_Exception(response.Exception_Message);
                return response.Division;
            })
        );
    }
    Delete_Project_entry_Custom(i_Params_Delete_Project_entry_Custom: Params_Delete_Project_entry_Custom): Observable<boolean> {
        const url = this.CmSvc.APIUrl + '/api/data/Delete_Project_entry_Custom';
        const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Ticket': this.CmSvc.Ticket });
        const options = { headers: headers };
        return this.api.post<Result_Delete_Project_entry_Custom>(url, JSON.stringify(i_Params_Delete_Project_entry_Custom), options)
            .pipe(map(response => {
                this.CmSvc.Handle_Stack_Trace(response.Stack_Trace);
                this.CmSvc.Handle_Exception(response.Exception_Message);
                return response.i_Result;
            })
        );
    }
    Edit_About_us(i_About_us: About_us): Observable<About_us> {
        const url = this.CmSvc.APIUrl + '/api/data/Edit_About_us';
        const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Ticket': this.CmSvc.Ticket });
        const options = { headers: headers };
        return this.api.post<Result_Edit_About_us>(url, JSON.stringify(i_About_us), options)
            .pipe(map(response => {
                this.CmSvc.Handle_Stack_Trace(response.Stack_Trace);
                this.CmSvc.Handle_Exception(response.Exception_Message);
                return response.About_us;
            })
        );
    }
    Get_Data_Management_Module(i_Params_Get_Data_Management_Module: Params_Get_Data_Management_Module): Observable<Data_Mgmt> {
        const url = this.CmSvc.APIUrl + '/api/data/Get_Data_Management_Module';
        const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Ticket': this.CmSvc.Ticket });
        const options = { headers: headers };
        return this.api.post<Result_Get_Data_Management_Module>(url, JSON.stringify(i_Params_Get_Data_Management_Module), options)
            .pipe(map(response => {
                this.CmSvc.Handle_Stack_Trace(response.Stack_Trace);
                this.CmSvc.Handle_Exception(response.Exception_Message);
                return response.i_Result;
            })
        );
    }
    Get_Website_Data(): Observable<Website_Data> {
        const url = this.CmSvc.APIUrl + '/api/data/Get_Website_Data';
        const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Ticket': this.CmSvc.Ticket });
        const options = { headers: headers };
        return this.api.post<Result_Get_Website_Data>(url, {}, options)
            .pipe(map(response => {
                this.CmSvc.Handle_Stack_Trace(response.Stack_Trace);
                this.CmSvc.Handle_Exception(response.Exception_Message);
                return response.i_Result;
            })
        );
    }
    Edit_Project_component(i_Project_component: Project_component): Observable<Project_component> {
        const url = this.CmSvc.APIUrl + '/api/data/Edit_Project_component';
        const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Ticket': this.CmSvc.Ticket });
        const options = { headers: headers };
        return this.api.post<Result_Edit_Project_component>(url, JSON.stringify(i_Project_component), options)
            .pipe(map(response => {
                this.CmSvc.Handle_Stack_Trace(response.Stack_Trace);
                this.CmSvc.Handle_Exception(response.Exception_Message);
                return response.Project_component;
            })
        );
    }
    Edit_Project_entry_List(i_Params_Edit_Project_entry_List: Params_Edit_Project_entry_List): Observable<Params_Edit_Project_entry_List> {
        const url = this.CmSvc.APIUrl + '/api/data/Edit_Project_entry_List';
        const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Ticket': this.CmSvc.Ticket });
        const options = { headers: headers };
        return this.api.post<Result_Edit_Project_entry_List>(url, JSON.stringify(i_Params_Edit_Project_entry_List), options)
            .pipe(map(response => {
                this.CmSvc.Handle_Stack_Trace(response.Stack_Trace);
                this.CmSvc.Handle_Exception(response.Exception_Message);
                return response.Params_Edit_Project_entry_List;
            })
        );
    }
    Send_Mail(i_Params_Send_Mail: Params_Send_Mail): Observable<boolean> {
        const url = this.CmSvc.APIUrl + '/api/data/Send_Mail';
        const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Ticket': this.CmSvc.Ticket });
        const options = { headers: headers };
        return this.api.post<Result_Send_Mail>(url, JSON.stringify(i_Params_Send_Mail), options)
            .pipe(map(response => {
                this.CmSvc.Handle_Stack_Trace(response.Stack_Trace);
                this.CmSvc.Handle_Exception(response.Exception_Message);
                return response.i_Result;
            })
        );
    }
    Edit_Service_List(i_Params_Edit_Service_List: Params_Edit_Service_List): Observable<Params_Edit_Service_List> {
        const url = this.CmSvc.APIUrl + '/api/data/Edit_Service_List';
        const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Ticket': this.CmSvc.Ticket });
        const options = { headers: headers };
        return this.api.post<Result_Edit_Service_List>(url, JSON.stringify(i_Params_Edit_Service_List), options)
            .pipe(map(response => {
                this.CmSvc.Handle_Stack_Trace(response.Stack_Trace);
                this.CmSvc.Handle_Exception(response.Exception_Message);
                return response.Params_Edit_Service_List;
            })
        );
    }
    Edit_Home_info_List(i_Params_Edit_Home_info_List: Params_Edit_Home_info_List): Observable<Params_Edit_Home_info_List> {
        const url = this.CmSvc.APIUrl + '/api/data/Edit_Home_info_List';
        const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Ticket': this.CmSvc.Ticket });
        const options = { headers: headers };
        return this.api.post<Result_Edit_Home_info_List>(url, JSON.stringify(i_Params_Edit_Home_info_List), options)
            .pipe(map(response => {
                this.CmSvc.Handle_Stack_Trace(response.Stack_Trace);
                this.CmSvc.Handle_Exception(response.Exception_Message);
                return response.Params_Edit_Home_info_List;
            })
        );
    }
    Edit_Parcel(i_Parcel: Parcel): Observable<Parcel> {
        const url = this.CmSvc.APIUrl + '/api/data/Edit_Parcel';
        const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Ticket': this.CmSvc.Ticket });
        const options = { headers: headers };
        return this.api.post<Result_Edit_Parcel>(url, JSON.stringify(i_Parcel), options)
            .pipe(map(response => {
                this.CmSvc.Handle_Stack_Trace(response.Stack_Trace);
                this.CmSvc.Handle_Exception(response.Exception_Message);
                return response.Parcel;
            })
        );
    }
    Delete_Home_info(i_Params_Delete_Home_info: Params_Delete_Home_info): Observable<string> {
        const url = this.CmSvc.APIUrl + '/api/data/Delete_Home_info';
        const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Ticket': this.CmSvc.Ticket });
        const options = { headers: headers };
        return this.api.post<Result_Delete_Home_info>(url, JSON.stringify(i_Params_Delete_Home_info), options)
            .pipe(map(response => {
                this.CmSvc.Handle_Stack_Trace(response.Stack_Trace);
                this.CmSvc.Handle_Exception(response.Exception_Message);
                return response.Exception_Message;
            })
        );
    }
    Export_Entries_Excel_Sheet(i_Params_Export_Entries_Excel_Sheet: Params_Export_Entries_Excel_Sheet): Observable<string> {
        const url = this.CmSvc.APIUrl + '/api/data/Export_Entries_Excel_Sheet';
        const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Ticket': this.CmSvc.Ticket });
        const options = { headers: headers };
        return this.api.post<Result_Export_Entries_Excel_Sheet>(url, JSON.stringify(i_Params_Export_Entries_Excel_Sheet), options)
            .pipe(map(response => {
                this.CmSvc.Handle_Stack_Trace(response.Stack_Trace);
                this.CmSvc.Handle_Exception(response.Exception_Message);
                return response.i_Result;
            })
        );
    }
    Edit_Work_package(i_Work_package: Work_package): Observable<Work_package> {
        const url = this.CmSvc.APIUrl + '/api/data/Edit_Work_package';
        const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Ticket': this.CmSvc.Ticket });
        const options = { headers: headers };
        return this.api.post<Result_Edit_Work_package>(url, JSON.stringify(i_Work_package), options)
            .pipe(map(response => {
                this.CmSvc.Handle_Stack_Trace(response.Stack_Trace);
                this.CmSvc.Handle_Exception(response.Exception_Message);
                return response.Work_package;
            })
        );
    }
    Delete_Service(i_Params_Delete_Service: Params_Delete_Service): Observable<string> {
        const url = this.CmSvc.APIUrl + '/api/data/Delete_Service';
        const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Ticket': this.CmSvc.Ticket });
        const options = { headers: headers };
        return this.api.post<Result_Delete_Service>(url, JSON.stringify(i_Params_Delete_Service), options)
            .pipe(map(response => {
                this.CmSvc.Handle_Stack_Trace(response.Stack_Trace);
                this.CmSvc.Handle_Exception(response.Exception_Message);
                return response.Exception_Message;
            })
        );
    }
    Edit_Uploaded_file(i_Uploaded_file: Uploaded_file): Observable<Uploaded_file> {
        const url = this.CmSvc.APIUrl + '/api/data/Edit_Uploaded_file';
        const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Ticket': this.CmSvc.Ticket });
        const options = { headers: headers };
        return this.api.post<Result_Edit_Uploaded_file>(url, JSON.stringify(i_Uploaded_file), options)
            .pipe(map(response => {
                this.CmSvc.Handle_Stack_Trace(response.Stack_Trace);
                this.CmSvc.Handle_Exception(response.Exception_Message);
                return response.Uploaded_file;
            })
        );
    }
    Edit_Section(i_Section: Section): Observable<Section> {
        const url = this.CmSvc.APIUrl + '/api/data/Edit_Section';
        const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Ticket': this.CmSvc.Ticket });
        const options = { headers: headers };
        return this.api.post<Result_Edit_Section>(url, JSON.stringify(i_Section), options)
            .pipe(map(response => {
                this.CmSvc.Handle_Stack_Trace(response.Stack_Trace);
                this.CmSvc.Handle_Exception(response.Exception_Message);
                return response.Section;
            })
        );
    }
    Delete_Work_Package_Custom(i_Params_Delete_Work_Package_Custom: Params_Delete_Work_Package_Custom): Observable<boolean> {
        const url = this.CmSvc.APIUrl + '/api/data/Delete_Work_Package_Custom';
        const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Ticket': this.CmSvc.Ticket });
        const options = { headers: headers };
        return this.api.post<Result_Delete_Work_Package_Custom>(url, JSON.stringify(i_Params_Delete_Work_Package_Custom), options)
            .pipe(map(response => {
                this.CmSvc.Handle_Stack_Trace(response.Stack_Trace);
                this.CmSvc.Handle_Exception(response.Exception_Message);
                return response.i_Result;
            })
        );
    }
    Edit_User(i_User: User): Observable<User> {
        const url = this.CmSvc.APIUrl + '/api/data/Edit_User';
        const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Ticket': this.CmSvc.Ticket });
        const options = { headers: headers };
        return this.api.post<Result_Edit_User>(url, JSON.stringify(i_User), options)
            .pipe(map(response => {
                this.CmSvc.Handle_Stack_Trace(response.Stack_Trace);
                this.CmSvc.Handle_Exception(response.Exception_Message);
                return response.User;
            })
        );
    }
    Edit_Contact_us(i_Contact_us: Contact_us): Observable<Contact_us> {
        const url = this.CmSvc.APIUrl + '/api/data/Edit_Contact_us';
        const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Ticket': this.CmSvc.Ticket });
        const options = { headers: headers };
        return this.api.post<Result_Edit_Contact_us>(url, JSON.stringify(i_Contact_us), options)
            .pipe(map(response => {
                this.CmSvc.Handle_Stack_Trace(response.Stack_Trace);
                this.CmSvc.Handle_Exception(response.Exception_Message);
                return response.Contact_us;
            })
        );
    }
    Get_Division_By_USER_ID_IS_DELETED(i_Params_Get_Division_By_USER_ID_IS_DELETED: Params_Get_Division_By_USER_ID_IS_DELETED): Observable<Division[]> {
        const url = this.CmSvc.APIUrl + '/api/data/Get_Division_By_USER_ID_IS_DELETED?USER_ID=' + i_Params_Get_Division_By_USER_ID_IS_DELETED.USER_ID + '&IS_DELETED=' + i_Params_Get_Division_By_USER_ID_IS_DELETED.IS_DELETED;
        const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Ticket': this.CmSvc.Ticket });
        const options = { headers: headers };
        return this.api.get<Result_Get_Division_By_USER_ID_IS_DELETED>(url, options)
            .pipe(map(response => {
                this.CmSvc.Handle_Stack_Trace(response.Stack_Trace);
                this.CmSvc.Handle_Exception(response.Exception_Message);
                return response.i_Result;
            })
        );
    }
    Get_User_By_USER_ID_Adv(i_Params_Get_User_By_USER_ID: Params_Get_User_By_USER_ID): Observable<User> {
        const url = this.CmSvc.APIUrl + '/api/data/Get_User_By_USER_ID_Adv?USER_ID=' + i_Params_Get_User_By_USER_ID.USER_ID;
        const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Ticket': this.CmSvc.Ticket });
        const options = { headers: headers };
        return this.api.get<Result_Get_User_By_USER_ID_Adv>(url, options)
            .pipe(map(response => {
                this.CmSvc.Handle_Stack_Trace(response.Stack_Trace);
                this.CmSvc.Handle_Exception(response.Exception_Message);
                return response.i_Result;
            })
        );
    }
    Delete_Uploaded_file(i_Params_Delete_Uploaded_file: Params_Delete_Uploaded_file): Observable<string> {
        const url = this.CmSvc.APIUrl + '/api/data/Delete_Uploaded_file';
        const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Ticket': this.CmSvc.Ticket });
        const options = { headers: headers };
        return this.api.post<Result_Delete_Uploaded_file>(url, JSON.stringify(i_Params_Delete_Uploaded_file), options)
            .pipe(map(response => {
                this.CmSvc.Handle_Stack_Trace(response.Stack_Trace);
                this.CmSvc.Handle_Exception(response.Exception_Message);
                return response.Exception_Message;
            })
        );
    }
    Get_Uploaded_file_By_REL_ENTITY_REL_KEY_REL_FIELD(i_Params_Get_Uploaded_file_By_REL_ENTITY_REL_KEY_REL_FIELD: Params_Get_Uploaded_file_By_REL_ENTITY_REL_KEY_REL_FIELD): Observable<Uploaded_file[]> {
        const url = this.CmSvc.APIUrl + '/api/data/Get_Uploaded_file_By_REL_ENTITY_REL_KEY_REL_FIELD?REL_ENTITY=' + i_Params_Get_Uploaded_file_By_REL_ENTITY_REL_KEY_REL_FIELD.REL_ENTITY + '&REL_KEY=' + i_Params_Get_Uploaded_file_By_REL_ENTITY_REL_KEY_REL_FIELD.REL_KEY + '&REL_FIELD=' + i_Params_Get_Uploaded_file_By_REL_ENTITY_REL_KEY_REL_FIELD.REL_FIELD;
        const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Ticket': this.CmSvc.Ticket });
        const options = { headers: headers };
        return this.api.get<Result_Get_Uploaded_file_By_REL_ENTITY_REL_KEY_REL_FIELD>(url, options)
            .pipe(map(response => {
                this.CmSvc.Handle_Stack_Trace(response.Stack_Trace);
                this.CmSvc.Handle_Exception(response.Exception_Message);
                return response.i_Result;
            })
        );
    }
}
export class About_us {
    List_Uploaded_file: Uploaded_file[];
    ABOUT_US_ID?: number;
    EXPERIENCE?: number;
    AUTHOR: string;
    DESIGNATION: string;
    DESCRIPTION: string;
    ABOUT_US_CAROUSEL: string;
    ABOUT_US_LOGO: string;
    ENTRY_USER_ID?: number;
    ENTRY_DATE: string;
    LAST_UPDATE: string;
    IS_DELETED: boolean;
    OWNER_ID?: number;
}
export class Activity {
    ACTIVITY_ID?: number;
    WORK_PACKAGE_ID?: number;
    PROJECT_ID?: number;
    ACTIVITY_CODE: string;
    ENTRY_USER_ID?: number;
    ENTRY_DATE: string;
    LAST_UPDATE: string;
    IS_DELETED: boolean;
    OWNER_ID?: number;
    Project: Project;
    Work_package: Work_package;
}
export class App_Settings {
    List_Setup_category: Setup_category[];
    List_Currency: Currency[];
}
export class Boq {
    BOQ_ID?: number;
    PAGE_ID?: number;
    BOQ_CODE: string;
    ENTRY_USER_ID?: number;
    ENTRY_DATE: string;
    LAST_UPDATE: string;
    IS_DELETED: boolean;
    OWNER_ID?: number;
    Page: Page;
}
export class Contact_us {
    CONTACT_US_ID?: number;
    PHONE_EXTENSION: string;
    PHONE: string;
    ADDRESS: string;
    EMAIL: string;
    ENTRY_USER_ID?: number;
    ENTRY_DATE: string;
    LAST_UPDATE: string;
    IS_DELETED: boolean;
    OWNER_ID?: number;
}
export class Cost_comp {
    Section: Section;
    COST_COMP_ID?: number;
    USER_ID?: number;
    SECTION_ID?: number;
    COST_COMP_CODE: string;
    DESCRIPTION: string;
    ENTRY_USER_ID?: number;
    ENTRY_DATE: string;
    LAST_UPDATE: string;
    IS_DELETED: boolean;
    OWNER_ID?: number;
    User: User;
}
export class Currency {
    CURRENCY_ID?: number;
    CURRENCY_NAME: string;
    SYMBOL: string;
    RATE_FROM_USD?: number;
    ENTRY_USER_ID?: number;
    ENTRY_DATE: string;
    LAST_UPDATE: string;
    IS_DELETED: boolean;
    OWNER_ID?: number;
}
export class Custom_Mail {
    oList_Mail: Mail[];
    TOTAL: number;
}
export class Data_Mgmt {
    COST_CODE_LIST: Cost_comp[];
    SECTION_LIST: Section[];
}
export class Division {
    DIVISION_ID?: number;
    USER_ID?: number;
    DIVISION_NAME: string;
    ENTRY_USER_ID?: number;
    ENTRY_DATE: string;
    LAST_UPDATE: string;
    IS_DELETED: boolean;
    OWNER_ID?: number;
    User: User;
    List_Section: Section[];
}
export class Facility {
    FACILITY_ID?: number;
    PROJECT_ID?: number;
    FACILITY_CODE: string;
    ENTRY_USER_ID?: number;
    ENTRY_DATE: string;
    LAST_UPDATE: string;
    IS_DELETED: boolean;
    OWNER_ID?: number;
    Project: Project;
    List_Page: Page[];
}
export class Home_header {
    HOME_HEADER_ID?: number;
    NAME: string;
    ENTRY_USER_ID?: number;
    ENTRY_DATE: string;
    LAST_UPDATE: string;
    IS_DELETED: boolean;
    OWNER_ID?: number;
}
export class Home_info {
    File_Url: string;
    HOME_INFO_ID?: number;
    HOME_HEADER_ID?: number;
    TITLE: string;
    SUBTITLE: string;
    ENTRY_USER_ID?: number;
    ENTRY_DATE: string;
    LAST_UPDATE: string;
    IS_DELETED: boolean;
    OWNER_ID?: number;
    DISPLAY_ORDER?: number;
    Home_header: Home_header;
}
export class Mail {
    MAIL_ID?: number;
    SUBJECT: string;
    NAME: string;
    EMAIL: string;
    MESSAGE_CONTENT: string;
    MESSAGE_DATE: string;
    ENTRY_USER_ID?: number;
    ENTRY_DATE: string;
    LAST_UPDATE: string;
    IS_DELETED: boolean;
    OWNER_ID?: number;
}
export class Our_mission {
    OUR_MISSION_ID?: number;
    VISION: string;
    MISSION: string;
    ENTRY_USER_ID?: number;
    ENTRY_DATE: string;
    LAST_UPDATE: string;
    IS_DELETED: boolean;
    OWNER_ID?: number;
}
export class Page {
    PAGE_ID?: number;
    FACILITY_ID?: number;
    PAGE_NUMBER: string;
    ENTRY_USER_ID?: number;
    ENTRY_DATE: string;
    LAST_UPDATE: string;
    IS_DELETED: boolean;
    OWNER_ID?: number;
    Facility: Facility;
    List_Boq: Boq[];
}
export class Parcel {
    PARCEL_ID?: number;
    PROJECT_ID?: number;
    PARCEL_CODE: string;
    ENTRY_USER_ID?: number;
    ENTRY_DATE: string;
    LAST_UPDATE: string;
    IS_DELETED: boolean;
    OWNER_ID?: number;
    Project: Project;
    List_Work_package: Work_package[];
}
export class Project {
    List_Project_entry: Project_entry[];
    PROJECT_ID?: number;
    CURRENCY_ID?: number;
    PROJECT_REFERENCE_CODE: string;
    PROJECT_NAME: string;
    LABOR_RATE?: number;
    TOTAL_LABOR_MHRS?: number;
    TOTAL_LABOR_COST?: number;
    TOTAL_MATERIAL_COST?: number;
    TOTAL_EQUIPMENT_COST?: number;
    TOTAL_SUBCONTRACT_COST?: number;
    TOTAL_SUNDRY_COST?: number;
    TOTAL_COST?: number;
    TOTAL_CLIENT_BOQ?: number;
    ENTRY_USER_ID?: number;
    ENTRY_DATE: string;
    LAST_UPDATE: string;
    IS_DELETED: boolean;
    OWNER_ID?: number;
    Currency: Currency;
    List_Project_user: Project_user[];
    List_Parcel: Parcel[];
    List_Facility: Facility[];
    List_Project_component_category: Project_component_category[];
}
export class Project_component {
    PROJECT_COMPONENT_ID?: number;
    PROJECT_COMPONENT_CATEGORY_ID?: number;
    PROJECT_COMPONENT_CODE: string;
    ENTRY_USER_ID?: number;
    ENTRY_DATE: string;
    LAST_UPDATE: string;
    IS_DELETED: boolean;
    OWNER_ID?: number;
    Project_component_category: Project_component_category;
}
export class Project_component_category {
    PROJECT_COMPONENT_CATEGORY_ID?: number;
    PROJECT_ID?: number;
    PROJECT_COMPONENT_CATEGORY_NAME: string;
    ENTRY_USER_ID?: number;
    ENTRY_DATE: string;
    LAST_UPDATE: string;
    IS_DELETED: boolean;
    OWNER_ID?: number;
    Project: Project;
    List_Project_component: Project_component[];
}
export class Project_Details {
    PROJECT: Project;
}
export class Project_Details_Dashboard {
    BCWP: number;
    ACWP: number;
    Cost_Variance: number;
    Cost_Performance_Index: number;
    Completed_Entries: number;
    Total_Entries: number;
}
export class Project_entry {
    PROJECT_ENTRY_ID?: number;
    PROJECT_ID?: number;
    PARCEL_ID?: number;
    WORK_PACKAGE_ID?: number;
    ACTIVITY_ID?: number;
    COST_COMP_ID?: number;
    CURRENCY_ID?: number;
    UNIT_ID?: number;
    PROJECT_ENTRY_STATUS_SETUP_ID?: number;
    FACILITY_ID?: number;
    PAGE_ID?: number;
    BOQ_ID?: number;
    QTB?: number;
    NEW_QTY?: number;
    UNIT_LABOR_PROD?: number;
    LABOR_MHRS?: number;
    MATERIAL_RATE?: number;
    EQUIPMENT_RATE?: number;
    SUNDRY_RATE?: number;
    SUBCONTRACT_RATE?: number;
    DESCRIPTION: string;
    SELLING_RATE?: number;
    CLIENT_BOQ?: number;
    UNIT_COST?: number;
    LABOR_COST?: number;
    MATERIAL_COST?: number;
    EQUIPMENT_COST?: number;
    SUNDRY_COST?: number;
    SUBCONTRACT_COST?: number;
    TOTAL_COST?: number;
    ENTRY_USER_ID?: number;
    ENTRY_DATE: string;
    LAST_UPDATE: string;
    IS_DELETED: boolean;
    OWNER_ID?: number;
    Activity: Activity;
    Boq: Boq;
    Cost_comp: Cost_comp;
    Currency: Currency;
    Page: Page;
    Parcel: Parcel;
    Project: Project;
    Unit: Unit;
    Work_package: Work_package;
    Facility: Facility;
    Project_entry_status_setup: Setup;
    List_Project_entry_project_component: Project_entry_project_component[];
}
export class Project_Entry_Custom {
    COUNT: number;
    PROJECT_ENTRY_LIST: Project_entry[];
    COMPONENT_CATEGORY_LIST: Project_component_category[];
    UNIT_LIST: Unit[];
    COST_COMP_LIST: Cost_comp[];
    TOTAL_LABOR_MHRS?: number;
    TOTAL_LABOR_COST?: number;
    TOTAL_MATERIAL_COST?: number;
    TOTAL_EQUIPMENT_COST?: number;
    TOTAL_SUBCONTRACT_COST?: number;
    TOTAL_SUNDRY_COST?: number;
    TOTAL_COST?: number;
    TOTAL_CLIENT_BOQ?: number;
}
export class Project_entry_project_component {
    PROJECT_ENTRY_PROJECT_COMPONENT_ID?: number;
    PROJECT_COMPONENT_ID?: number;
    PROJECT_ENTRY_ID?: number;
    ENTRY_USER_ID?: number;
    ENTRY_DATE: string;
    LAST_UPDATE: string;
    IS_DELETED: boolean;
    OWNER_ID?: number;
    DESCRIPTION: string;
    Project_entry: Project_entry;
    Project_component: Project_component;
}
export class Project_user {
    PROJECT_USER_ID?: number;
    PROJECT_ID?: number;
    USER_ID?: number;
    ENTRY_USER_ID?: number;
    ENTRY_DATE: string;
    LAST_UPDATE: string;
    IS_DELETED: boolean;
    OWNER_ID?: number;
    DESCRIPTION: string;
    Project: Project;
    User: User;
}
export class Section {
    SECTION_ID?: number;
    DIVISION_ID?: number;
    SECTION_NAME: string;
    ENTRY_USER_ID?: number;
    ENTRY_DATE: string;
    LAST_UPDATE: string;
    IS_DELETED: boolean;
    OWNER_ID?: number;
    Division: Division;
}
export class Service {
    File_Url: string;
    SERVICE_ID?: number;
    TITLE: string;
    DESCRIPTION: string;
    DISPLAY_ORDER?: number;
    ENTRY_USER_ID?: number;
    ENTRY_DATE: string;
    LAST_UPDATE: string;
    IS_DELETED: boolean;
    OWNER_ID?: number;
}
export class Setup {
    SETUP_ID?: number;
    SETUP_CATEGORY_ID?: number;
    IS_SYSTEM: boolean;
    IS_DELETEABLE: boolean;
    IS_UPDATEABLE: boolean;
    IS_DELETED: boolean;
    IS_VISIBLE: boolean;
    DISPLAY_ORDER?: number;
    VALUE: string;
    DESCRIPTION: string;
    ENTRY_USER_ID?: number;
    ENTRY_DATE: string;
    LAST_UPDATE: string;
    OWNER_ID?: number;
    Setup_category: Setup_category;
}
export class Setup_category {
    SETUP_CATEGORY_ID?: number;
    SETUP_CATEGORY_NAME: string;
    DESCRIPTION: string;
    ENTRY_USER_ID?: number;
    ENTRY_DATE: string;
    LAST_UPDATE: string;
    IS_DELETED: boolean;
    OWNER_ID?: number;
    List_Setup: Setup[];
}
export class Unit {
    UNIT_ID?: number;
    UNIT_CODE: string;
    DESCRIPTION: string;
    ENTRY_USER_ID?: number;
    ENTRY_DATE: string;
    LAST_UPDATE: string;
    IS_DELETED: boolean;
    OWNER_ID?: number;
}
export class Uploaded_file {
    File_Url: string;
    UPLOADED_FILE_ID?: number;
    REL_ENTITY: string;
    REL_KEY?: number;
    REL_FIELD: string;
    UID: string;
    SIZE?: number;
    EXTENSION: string;
    ENTRY_USER_ID?: number;
    ENTRY_DATE: string;
    OWNER_ID?: number;
    LAST_UPDATE: string;
    IS_DELETED: boolean;
}
export class User {
    USER_ID?: number;
    USER_TYPE_SETUP_ID?: number;
    PASSWORD: string;
    FULL_NAME: string;
    IS_ACTIVE: boolean;
    EMAIL: string;
    PHONE_NUMBER: string;
    ENTRY_USER_ID?: number;
    ENTRY_DATE: string;
    LAST_UPDATE: string;
    IS_DELETED: boolean;
    OWNER_ID?: number;
    User_type_setup: Setup;
    List_Project_user: Project_user[];
}
export class User_Details {
    Ticket: string;
    USER_ID?: number;
    OWNER_ID?: number;
    EMAIL: string;
    FULL_NAME: string;
    USER_TYPE_SETUP: Setup;
}
export class Website_Data {
    ABOUT_US: About_us;
    CONTACT_US: Contact_us;
    HOME_INFO: Home_info[];
    OUR_MISSION: Our_mission;
    SERVICES: Service[];
    UPLOADED_FILES: Uploaded_file[];
}
export class Work_package {
    WORK_PACKAGE_ID?: number;
    PROJECT_ID?: number;
    PARCEL_ID?: number;
    WORK_PACKAGE_CODE: string;
    ENTRY_USER_ID?: number;
    ENTRY_DATE: string;
    LAST_UPDATE: string;
    IS_DELETED: boolean;
    OWNER_ID?: number;
    Parcel: Parcel;
    Project: Project;
    List_Activity: Activity[];
}
export class Params_Delete_User_Custom {
    USER_ID?: number;
}
export class Params_Get_Project_Details_Dashboard {
    PROJECT_ID?: number;
}
export class Params_Delete_Project_Component_Category_Custom {
    PROJECT_COMPONENT_CATEGORY_ID?: number;
}
export class Params_Delete_Project_Custom {
    PROJECT_ID?: number;
}
export class Params_Delete_Uploaded_file_By_REL_ENTITY_REL_KEY_REL_FIELD {
    REL_ENTITY: string;
    REL_KEY?: number;
    REL_FIELD: string;
}
export class Params_Delete_Mail {
    MAIL_ID?: number;
}
export class Params_Get_Project_List {
    USER_ID?: number;
    USER_TYPE_SETUP_ID?: number;
}
export class Params_Get_Project_entry_List {
    PROJECT_ID?: number;
    OFFSET?: number;
    FETCH_NEXT?: number;
    LIST_PARCEL_ID: number[];
    LIST_FACILITY_ID: number[];
    LIST_ACTIVITY_ID: number[];
    LIST_COST_COMP_ID: number[];
    LIST_WORK_PACKAGE_ID: number[];
    LIST_PROJECT_COMPONENT_ID: number[];
    LIST_ENTRY_STATUS_SETUP_ID: number[];
}
export class Params_Change_User_Password {
    USER_ID?: number;
    NEW_PASSWORD: string;
}
export class Params_Authenticate_User {
    EMAIL: string;
    PASSWORD: string;
}
export class Params_Get_Mail {
    OFFSET?: number;
    FETCH_NEXT?: number;
    START_DATE: string;
    END_DATE: string;
}
export class Params_Delete_Project_Component_Custom {
    PROJECT_COMPONENT_ID?: number;
}
export class Params_Get_User_By_OWNER_ID_IS_DELETED {
    OWNER_ID?: number;
    IS_DELETED: boolean;
}
export class Params_Get_Project_Details {
    PROJECT_ID?: number;
    USER_DETAILS: User_Details;
}
export class Params_Delete_Project_entry_Custom {
    PROJECT_ENTRY_ID?: number;
}
export class Params_Get_Data_Management_Module {
    USER_ID?: number;
}
export class Params_Edit_Project_entry_List {
    List_To_Edit: Project_entry[];
    List_Failed_Edit: Project_entry[];
    List_To_Delete: number[];
    List_Failed_Delete: number[];
}
export class Params_Send_Mail {
    SUBJECT: string;
    SENDER_NAME: string;
    SENDER_MAIL: string;
    SENDER_MESSAGE: string;
}
export class Params_Edit_Service_List {
    List_To_Edit: Service[];
    List_Failed_Edit: Service[];
    List_To_Delete: number[];
    List_Failed_Delete: number[];
}
export class Params_Edit_Home_info_List {
    List_To_Edit: Home_info[];
    List_Failed_Edit: Home_info[];
    List_To_Delete: number[];
    List_Failed_Delete: number[];
}
export class Params_Delete_Home_info {
    HOME_INFO_ID?: number;
}
export class Params_Export_Entries_Excel_Sheet {
    PROJECT_ID?: number;
}
export class Params_Delete_Service {
    SERVICE_ID?: number;
}
export class Params_Delete_Work_Package_Custom {
    WORK_PACKAGE_ID?: number;
}
export class Params_Get_Division_By_USER_ID_IS_DELETED {
    USER_ID?: number;
    IS_DELETED: boolean;
}
export class Params_Get_User_By_USER_ID {
    USER_ID?: number;
}
export class Params_Delete_Uploaded_file {
    UPLOADED_FILE_ID?: number;
}
export class Params_Get_Uploaded_file_By_REL_ENTITY_REL_KEY_REL_FIELD {
    REL_ENTITY: string;
    REL_KEY?: number;
    REL_FIELD: string;
}
export class Action_Result {
    Stack_Trace: string;
    Exception_Message: string;
}
export class Result_Delete_User_Custom extends Action_Result {
    i_Result: boolean;
    Params_Delete_User_Custom: Params_Delete_User_Custom;
}
export class Result_Get_Project_Details_Dashboard extends Action_Result {
    i_Result: Project_Details_Dashboard;
    Params_Get_Project_Details_Dashboard: Params_Get_Project_Details_Dashboard;
}
export class Result_Delete_Project_Component_Category_Custom extends Action_Result {
    i_Result: boolean;
    Params_Delete_Project_Component_Category_Custom: Params_Delete_Project_Component_Category_Custom;
}
export class Result_Delete_Project_Custom extends Action_Result {
    i_Result: boolean;
    Params_Delete_Project_Custom: Params_Delete_Project_Custom;
}
export class Result_Edit_Home_info extends Action_Result {
    Home_info: Home_info;
}
export class Result_Edit_Project_entry extends Action_Result {
    Project_entry: Project_entry;
}
export class Result_Delete_Uploaded_file_By_REL_ENTITY_REL_KEY_REL_FIELD extends Action_Result {
    Params_Delete_Uploaded_file_By_REL_ENTITY_REL_KEY_REL_FIELD: Params_Delete_Uploaded_file_By_REL_ENTITY_REL_KEY_REL_FIELD;
}
export class Result_Delete_Mail extends Action_Result {
    Params_Delete_Mail: Params_Delete_Mail;
}
export class Result_Edit_Project extends Action_Result {
    Project: Project;
}
export class Result_Get_Project_List extends Action_Result {
    i_Result: Project[];
    Params_Get_Project_List: Params_Get_Project_List;
}
export class Result_Edit_Service extends Action_Result {
    Service: Service;
}
export class Result_Get_Project_entry_List extends Action_Result {
    i_Result: Project_Entry_Custom;
    Params_Get_Project_entry_List: Params_Get_Project_entry_List;
}
export class Result_Change_User_Password extends Action_Result {
    i_Result: boolean;
    Params_Change_User_Password: Params_Change_User_Password;
}
export class Result_Edit_Our_mission extends Action_Result {
    Our_mission: Our_mission;
}
export class Result_Authenticate_User extends Action_Result {
    i_Result: User_Details;
    Params_Authenticate_User: Params_Authenticate_User;
}
export class Result_Edit_Facility extends Action_Result {
    Facility: Facility;
}
export class Result_Get_Mail extends Action_Result {
    i_Result: Custom_Mail;
    Params_Get_Mail: Params_Get_Mail;
}
export class Result_Edit_Cost_comp extends Action_Result {
    Cost_comp: Cost_comp;
}
export class Result_Edit_Page extends Action_Result {
    Page: Page;
}
export class Result_Delete_Project_Component_Custom extends Action_Result {
    i_Result: boolean;
    Params_Delete_Project_Component_Custom: Params_Delete_Project_Component_Custom;
}
export class Result_Edit_Project_component_category extends Action_Result {
    Project_component_category: Project_component_category;
}
export class Result_Get_User_By_OWNER_ID_IS_DELETED_Adv extends Action_Result {
    i_Result: User[];
    Params_Get_User_By_OWNER_ID_IS_DELETED: Params_Get_User_By_OWNER_ID_IS_DELETED;
}
export class Result_Get_Project_Details extends Action_Result {
    i_Result: Project_Details;
    Params_Get_Project_Details: Params_Get_Project_Details;
}
export class Result_Get_App_Settings extends Action_Result {
    i_Result: App_Settings;
}
export class Result_Edit_Division extends Action_Result {
    Division: Division;
}
export class Result_Delete_Project_entry_Custom extends Action_Result {
    i_Result: boolean;
    Params_Delete_Project_entry_Custom: Params_Delete_Project_entry_Custom;
}
export class Result_Edit_About_us extends Action_Result {
    About_us: About_us;
}
export class Result_Get_Data_Management_Module extends Action_Result {
    i_Result: Data_Mgmt;
    Params_Get_Data_Management_Module: Params_Get_Data_Management_Module;
}
export class Result_Get_Website_Data extends Action_Result {
    i_Result: Website_Data;
}
export class Result_Edit_Project_component extends Action_Result {
    Project_component: Project_component;
}
export class Result_Edit_Project_entry_List extends Action_Result {
    Params_Edit_Project_entry_List: Params_Edit_Project_entry_List;
}
export class Result_Send_Mail extends Action_Result {
    i_Result: boolean;
    Params_Send_Mail: Params_Send_Mail;
}
export class Result_Edit_Service_List extends Action_Result {
    Params_Edit_Service_List: Params_Edit_Service_List;
}
export class Result_Edit_Home_info_List extends Action_Result {
    Params_Edit_Home_info_List: Params_Edit_Home_info_List;
}
export class Result_Edit_Parcel extends Action_Result {
    Parcel: Parcel;
}
export class Result_Delete_Home_info extends Action_Result {
    Params_Delete_Home_info: Params_Delete_Home_info;
}
export class Result_Export_Entries_Excel_Sheet extends Action_Result {
    i_Result: string;
    Params_Export_Entries_Excel_Sheet: Params_Export_Entries_Excel_Sheet;
}
export class Result_Edit_Work_package extends Action_Result {
    Work_package: Work_package;
}
export class Result_Delete_Service extends Action_Result {
    Params_Delete_Service: Params_Delete_Service;
}
export class Result_Edit_Uploaded_file extends Action_Result {
    Uploaded_file: Uploaded_file;
}
export class Result_Edit_Section extends Action_Result {
    Section: Section;
}
export class Result_Delete_Work_Package_Custom extends Action_Result {
    i_Result: boolean;
    Params_Delete_Work_Package_Custom: Params_Delete_Work_Package_Custom;
}
export class Result_Edit_User extends Action_Result {
    User: User;
}
export class Result_Edit_Contact_us extends Action_Result {
    Contact_us: Contact_us;
}
export class Result_Get_Division_By_USER_ID_IS_DELETED extends Action_Result {
    i_Result: Division[];
    Params_Get_Division_By_USER_ID_IS_DELETED: Params_Get_Division_By_USER_ID_IS_DELETED;
}
export class Result_Get_User_By_USER_ID_Adv extends Action_Result {
    i_Result: User;
    Params_Get_User_By_USER_ID: Params_Get_User_By_USER_ID;
}
export class Result_Delete_Uploaded_file extends Action_Result {
    Params_Delete_Uploaded_file: Params_Delete_Uploaded_file;
}
export class Result_Get_Uploaded_file_By_REL_ENTITY_REL_KEY_REL_FIELD extends Action_Result {
    i_Result: Uploaded_file[];
    Params_Get_Uploaded_file_By_REL_ENTITY_REL_KEY_REL_FIELD: Params_Get_Uploaded_file_By_REL_ENTITY_REL_KEY_REL_FIELD;
}
