<div class="comment-body">
    <div class="comment-meta">
        <a href="javascript:void(0);">{{data.date}}</a>
    </div>
    <div class="comment-author vcard">
        <img class="avatar photo" src="{{data.image}}" alt="">
        <cite class="fn"> {{data.name}} </cite>
        <span class="says">says:</span>
    </div>

    <p>{{data.description}} </p>
    <div class="reply">
        <a href="javascript:void(0);" class="comment-reply-link letter-spacing-2 text-uppercase">Reply</a>
    </div>
</div>