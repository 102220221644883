<div class="page-wraper">

    <!-- HEADER START -->
    <app-header></app-header>
    <!-- HEADER END -->


    <!-- CONTENT START -->
    <div class="page-content">

        <!-- INNER PAGE BANNER -->
        <app-banner [data]="banner"></app-banner>
        <!-- INNER PAGE BANNER END -->

        <!-- SECTION CONTENTG -->
        <div class="section-full inner-page-padding p-tb80">
            <div class="container">
                <div class="row">
                    <div class="col-md-3 col-sm-4">
                        <app-left-sidebar1></app-left-sidebar1>
                    </div>
                    <div class="col-md-9 col-sm-8">
                        <div class="section-content">
                            <!-- Youtube Video -->
                            <div class="m-b50">

                                <!-- TITLE START -->
                                <div class="section-head">
                                    <div class="mt-separator-outer separator-left">
                                        <div class="mt-separator">
                                            <h2 class="text-black text-uppercase sep-line-one "><span
                                                    class="font-weight-300 text-primary">Youtube</span> video</h2>
                                        </div>
                                    </div>
                                </div>
                                <!-- TITLE END -->
                                <iframe width="560" height="315"
                                    src="https://www.youtube.com/embed/s3A7AK1K2hc"></iframe>
                            </div>

                            <!-- Vimeo Video -->
                            <div class="m-b50">

                                <!-- TITLE START -->
                                <div class="section-head">
                                    <div class="mt-separator-outer separator-left">
                                        <div class="mt-separator">
                                            <h2 class="text-black text-uppercase sep-line-one "><span
                                                    class="font-weight-300 text-primary">Vimeo</span> video</h2>
                                        </div>
                                    </div>
                                </div>
                                <!-- TITLE END -->
                                <iframe src="https://player.vimeo.com/video/112734492" width="640"
                                    height="360"></iframe>
                            </div>

                            <!-- Youtube Video With Popup -->
                            <div class="m-b50">
                                <!-- TITLE START -->
                                <div class="section-head">
                                    <div class="mt-separator-outer separator-left">
                                        <div class="mt-separator">
                                            <h2 class="text-black text-uppercase sep-line-one "><span
                                                    class="font-weight-300 text-primary">Youtube video </span> with
                                                popup</h2>
                                        </div>
                                    </div>
                                </div>
                                <!-- TITLE END -->

                                <div class="mt-box">
                                    <div class="mt-thum-bx mt-img-overlay1 mt-img-effect yt-thum-box">
                                        <img src="http://i3.ytimg.com/vi/s3A7AK1K2hc/maxresdefault.jpg" alt="">
                                        <a href="https://www.youtube.com/watch?v=OfFFZnOtqFQ"
                                            class="mfp-video play-now">
                                            <i class="icon fa fa-play"></i>
                                            <span class="ripple"></span>
                                        </a>
                                    </div>
                                </div>
                            </div>

                            <!-- Vimeo Video With popup -->
                            <div class="m-b10">
                                <!-- TITLE START -->
                                <div class="section-head">
                                    <div class="mt-separator-outer separator-left">
                                        <div class="mt-separator">
                                            <h2 class="text-black text-uppercase sep-line-one "><span
                                                    class="font-weight-300 text-primary">vimeo video </span> with popup
                                            </h2>
                                        </div>
                                    </div>
                                </div>
                                <!-- TITLE END -->
                                <div class="mt-box">
                                    <div class="mt-thum-bx mt-img-overlay1">
                                        <img src="https://i.vimeocdn.com/video/497807626_590x332.jpg" alt="">
                                        <a href="https://player.vimeo.com/video/112734492" class="mfp-video play-now">
                                            <i class="icon fa fa-play"></i>
                                            <span class="ripple"></span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
    <!-- CONTENT END -->

    <!-- FOOTER START -->
    <app-footer></app-footer>
    <!-- FOOTER END -->

</div>

<app-loader></app-loader>