import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-call-us',
  templateUrl: './call-us.component.html',
  styleUrls: ['./call-us.component.css']
})
export class CallUsComponent{
  
  @Input() data: any;

  constructor() { }

}
