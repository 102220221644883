<div class="mt-icon-box-wraper m-b30">
    <div style="display: flex; flex-direction: column; flex: 1 1 auto;"
        class="relative icon-count-2 bg-gray p-a30 p-tb50">
        <span class="icon-count-number">{{data.serial}}</span>
        <div class="icon-md inline-icon m-b15 text-primary ">
            <span class="icon-cell" style="float: left !important;"><img src="{{data.icon}}" alt=""></span>
        </div>
        <div class="icon-content">
            <h4 class="mt-tilte m-b25 override-word-spacing" style="margin-top: 10%; height: 80px !important;">
                {{data.title}}</h4>
            <a style="cursor: pointer;" class="site-button-link" data-hover="Read More" data-toggle="modal"
                [attr.data-target]="'#Modal'+data.ID">Read
                More
                <i class="fa fa-angle-right arrow-animation"></i></a>
        </div>
        <div id="Modal{{data.ID}}" class="modal fade" role="dialog">
            <div class="modal-dialog">
                <!-- MODAL CONTENT-->
                <div class="modal-content">
                    <div class="modal-header bg-secondary">
                        <button type="button" class="close" data-dismiss="modal">&times;</button>
                        <h4 class="modal-title text-white">{{data.title}}</h4>
                    </div>
                    <div class="modal-body" style="white-space:pre-line; font-size: 15px;">
                        <p style="line-height: 33px;">{{data.description}}</p>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="site-button btn-effect button-sm text-uppercase letter-spacing-2"
                            data-dismiss="modal">Close</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>