import { ViewportScroller } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { About_us, Contact_us, Our_mission } from 'src/app/core/Services/proxy.service';
import { WebsiteManagementService } from 'src/app/core/Services/website-management.service';


export interface service {
  title: string,
  serial: number,
  icon: string,
  description: string,
  ID: number
}
export interface home_info {
  title: string,
  subtitle: string,
  url: string
}

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  isLoaded = false;
  about: any;
  contact_us: any;
  Signature_URL: string = '';

  oAbout_us: About_us = new About_us();
  oContact_us: Contact_us = new Contact_us();
  oOur_mission: Our_mission = new Our_mission();
  oList_Service: service[] = [];
  oList_Home_Info: home_info[] = [];

  constructor(
    private websiteMgmt: WebsiteManagementService,
    private viewportScroller: ViewportScroller) {
    this.websiteMgmt.Get_Website_Data().then(result => {
      if (result) {
        result.HOME_INFO.forEach(oHome_Info => {
          this.oList_Home_Info.push({ title: oHome_Info.TITLE, subtitle: oHome_Info.SUBTITLE, url: oHome_Info.File_Url })
        }),
          this.oAbout_us = result.ABOUT_US,
          this.oOur_mission = result.OUR_MISSION,
          this.oContact_us = result.CONTACT_US,
          result.SERVICES.forEach(service => {
            this.oList_Service.push({
              description: service.DESCRIPTION,
              icon: service.File_Url,
              serial: service.DISPLAY_ORDER,
              title: service.TITLE,
              ID: service.SERVICE_ID
            });
          }),
          this.Signature_URL = this.oAbout_us.List_Uploaded_file[this.oAbout_us.List_Uploaded_file.reverse().findIndex(uploaded_file => uploaded_file.REL_FIELD == "ABOUT_US_LOGO")].File_Url;
        this.about = {
          slides: this.oAbout_us.List_Uploaded_file.filter(uploaded_file => uploaded_file.REL_FIELD == "ABOUT_US_CAROUSEL").map(uploaded_file => uploaded_file.File_Url),
          experience: this.oAbout_us.EXPERIENCE?.toString(),
          author: this.oAbout_us.AUTHOR,
          sign: this.Signature_URL,
          designation: this.oAbout_us.DESIGNATION,
          description: this.oAbout_us.DESCRIPTION
        },
          this.contact_us = {
            phone_extension: result.CONTACT_US.PHONE_EXTENSION,
            phone: result.CONTACT_US.PHONE,
            address: result.CONTACT_US.ADDRESS,
            email: result.CONTACT_US.EMAIL
          },
          this.isLoaded = true;
      }
    });
  }

  ngOnInit(): void {
  }
  scroll(el: HTMLElement) {
    el.scrollIntoView({ behavior: 'smooth' });
  }


  scrollToElement(dest: string): void {
    this.viewportScroller.scrollToAnchor(dest);
  }

}
