<footer class="site-footer footer-large footer-dark	footer-wide">
    <!-- FOOTER BLOCKES START -->
    <div class="footer-top overlay-wraper">
        <div class="overlay-main"></div>
        <div class="container">
            <div class="row">
                <!-- ABOUT COMPANY -->
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="widget widget_about">
                        <!--<h4 class="widget-title">About Company</h4>-->
                        <div *ngIf="page != '4'" class="logo-footer clearfix p-b15">
                            <a routerLink="/index"><img src="assets/images/logo-dark.png" alt=""></a>
                        </div>
                        <div *ngIf="page == '4'" class="logo-footer clearfix p-b15">
                            <a routerLink="/index"><img src="assets/images/logo-light-7.png" alt=""></a>
                        </div>
                        <p>We'd love to hear from you.<br>Our team is ready to answer all your questions.</p>

                        <!-- <ul class="social-icons  mt-social-links">
                            <li><a href="https://www.google.com" class="fa fa-google"></a></li>
                            <li><a href="https://rss.com" class="fa fa-rss"></a></li>
                            <li><a href="https://www.facebook.com" class="fa fa-facebook"></a></li>
                            <li><a href="https://twitter.com" class="fa fa-twitter"></a></li>
                            <li><a href="https://in.linkedin.com" class="fa fa-linkedin"></a></li>
                        </ul> -->
                    </div>
                </div>

                <!-- RESENT POST -->
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="widget widget_address_outer">
                        <h4 class="widget-title">Contact Us</h4>
                        <ul class="widget_address">
                            <li [innerText]="data.address"></li>
                            <li>{{data.email}}</li>
                            <li>{{data.phone_extension}} {{data.phone}}</li>
                        </ul>

                    </div>
                </div>

                <!-- USEFUL LINKS -->
                <div class="col-lg-4 col-md-6 col-sm-6 footer-col-3">
                    <div class="widget widget_services inline-links">
                        <h4 class="widget-title">Sitemap</h4>
                        <ul>
                            <li><a class="footer-button" (click)="emitDestination('Home')">Home</a></li>
                            <li><a class="footer-button" (click)="emitDestination('about')">About Us</a></li>
                            <li><a class="footer-button" (click)="emitDestination('services')">Services</a></li>
                            <li><a class="footer-button" (click)="emitDestination('mission')">Our Mission</a></li>
                            <li><a class="footer-button" (click)="emitDestination('vision')">Our Vision</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- FOOTER COPYRIGHT -->
    <div class="footer-bottom overlay-wraper">
        <div class="overlay-main"></div>
        <div class="container">
            <div class="row">
                <div class="mt-footer-bot-center">
                    <span class="copyrights-text"><span style="color: #70aee3;">Tektran</span> &copy; {{Today}} | All Rights Reserved.</span>
                </div>
            </div>
        </div>
    </div>
</footer>