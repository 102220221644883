<div class="our-team-1 hover-animation-1">
    <div class="profile-image "><img src="{{data.image}}" alt="" />
    </div>
    <div class="figcaption">
        <h4>{{data.name}} </h4>
        <h5>{{data.designation}}</h5>
        <p>{{data.description}}</p>
        <div class="icons">
            <a href="https://www.facebook.com"><i class="fa fa-facebook"></i></a>
            <a href="https://twitter.com"> <i class="fa fa-twitter"></i></a>
            <a href="https://www.instagram.com"> <i class="fa fa-instagram"></i></a>
            <a href="https://rss.com"><i class="fa fa-rss"></i></a>
            <a href="https://www.youtube.com"> <i class="fa fa-youtube"></i></a>
            <a href="https://in.linkedin.com"> <i class="fa fa-linkedin"></i></a>
        </div>
    </div>

</div>