<header class="site-header header-style-1">
    <div class="sticky-header main-bar-wraper">
        <div class="main-bar bg-white">
            <div class="container">
                <div class="logo-header" [ngStyle]="{'width': mobileView ? '140px' : '230px' }">
                    <div class="logo-header-inner">
                        <a routerLink="/index">
                            <img src="assets/images/logo-light.png" alt="" />
                        </a>
                    </div>
                </div>
                <!-- NAV Toggle Button -->
                <button data-target=".header-nav" data-toggle="collapse" type="button" class="navbar-toggle collapsed">
                    <span class="sr-only">Toggle navigation</span>
                    <span class="icon-bar"></span>
                    <span class="icon-bar"></span>
                    <span class="icon-bar"></span>
                </button>

                <!-- MAIN Vav -->
                <div class="header-nav navbar-collapse collapse">
                    <ul class=" nav navbar-nav">
                        <li>
                            <a (click)="emitDestination('Home')">Home</a>
                        </li>

                        <li>
                            <a (click)="emitDestination('about')">About us</a>
                        </li>
                        <li>
                            <a (click)="emitDestination('services')">Services</a>
                        </li>
                        <li>
                            <a (click)="emitDestination('mission')">Mission</a>
                        </li>
                        <li>
                            <a (click)="emitDestination('vision')">Vision</a>
                        </li>
                        <li>
                            <a data-toggle="modal" data-target="#with-form">Contact Us</a>
                        </li>
                    </ul>
                    <div class="button-model col-md-3 col-sm-6">

                        <!-- MODAL -->
                        <div id="with-form" class="modal fade" role="dialog">
                            <div class="modal-dialog">
                                <!-- Modal content-->
                                <div class="modal-content">
                                    <div class="modal-header bg-secondary">
                                        <button id="closeButton" type="button" class="close"
                                            data-dismiss="modal">&times;</button>
                                        <h4 class="modal-title text-white">Contact Us</h4>
                                    </div>
                                    <div class="modal-body-contact-us">
                                        <form #contactForm="ngForm">
                                            <div class="form-group mt-lg" style="margin-top: 10px;">
                                                <label class="col-sm-3 control-label">Name</label>
                                                <div class="col-sm-9">
                                                    <input name="name" class="form-control"
                                                        placeholder="Enter your name" required type="text"
                                                        [(ngModel)]="name">
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <label class="col-sm-3 control-label">Email</label>
                                                <div class="col-sm-9">
                                                    <input name="email" class="form-control"
                                                        placeholder="Enter your email" required type="email"
                                                        [(ngModel)]="email">
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <label class="col-sm-3 control-label">Subject</label>
                                                <div class="col-sm-9">
                                                    <input name="subject" class="form-control" placeholder="Subject"
                                                        required [(ngModel)]="subject">
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <label class="col-sm-3 control-label">Message</label>
                                                <div class="col-sm-9">
                                                    <textarea style=" height: 100px; resize: none;" name="message"
                                                        rows="5" class="form-control"
                                                        placeholder="Enter your message..." required
                                                        [(ngModel)]="message"></textarea>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                    <div class="modal-footer">
                                        <button type="button" [disabled]="contactForm.disabled"
                                            class="site-button btn-effect text-uppercase button-sm letter-spacing-2"
                                            (click)="sendMail()">Confirm</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</header>