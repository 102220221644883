<div class="acod-head acc-actives">
    <h6 class="acod-title">
        <a data-toggle="collapse" href="#collapse{{data.serial}}5"  class="{{data.serial != 1 ? 'collapsed' : ''}}" data-parent="#accordion5">
            {{data.title}}
            <span class="indicator"><i class="fa"></i></span>
        </a>
    </h6>
</div>
<div id="collapse{{data.serial}}5" class="acod-body collapse {{data.serial != 1 ? '' : 'in'}}">
    <div class="acod-content p-tb15">
        <p> {{data.description}}</p>
    </div>
</div>