<div class="page-wraper">

    <!-- HEADER START -->
    <app-header></app-header>
    <!-- HEADER END -->

    <!-- CONTENT START -->
    <div class="page-content">

        <!-- INNER PAGE BANNER -->
        <app-banner [data]="banner"></app-banner>
        <!-- INNER PAGE BANNER END -->

        <!-- SECTION CONTENTG START -->
        <div class="section-full p-tb80 inner-page-padding">
            <div class="container">
                <div class="row">
                    <div class="col-md-3 col-sm-4">
                        <app-left-sidebar1></app-left-sidebar1>
                    </div>

                    <div class="col-md-9 col-sm-8">
                        <div class="m-b50">
                            <!-- TITLE START -->
                            <div class="section-head">
                                <div class="mt-separator-outer separator-left">
                                    <div class="mt-separator">
                                        <h2 class="text-black text-uppercase sep-line-one "><span
                                                class="font-weight-300 text-primary">Border</span> Button</h2>
                                    </div>
                                </div>
                            </div>
                            <!-- TITLE END -->

                            <div class="section-content">
                                <a href="javascript:void(0);"
                                    class="site-button outline  black button-app m-r15 m-b15">Download on the <strong
                                        class="text-center">Google play</strong></a>
                                <a href="javascript:void(0);"
                                    class="site-button outline  black button-app m-r15 m-b15">Download on the <strong
                                        class="text-center">App Store</strong></a>
                            </div>

                        </div>

                        <div class="m-b10">
                            <div class="mt-divider bg-gray-dark text-gray-dark icon-center"><i
                                    class="fa fa-circle bg-white text-gray-dark"></i></div>
                        </div>

                        <div class="m-b50">
                            <!-- TITLE START -->
                            <div class="section-head">
                                <div class="mt-separator-outer separator-left">
                                    <div class="mt-separator">
                                        <h2 class="text-black text-uppercase sep-line-one "><span
                                                class="font-weight-300 text-primary">Site</span> Button</h2>
                                    </div>
                                </div>
                            </div>
                            <!-- TITLE END -->

                            <div class="section-content">
                                <button class="m-b15 site-button btn-effect m-r15 text-uppercase" type="button">Button
                                    Pramary</button>
                                <button class="m-b15 site-button-secondary btn-effect text-uppercase "
                                    type="button">Button secondary</button>
                            </div>

                        </div>

                        <div class="m-b10">
                            <div class="mt-divider bg-gray-dark text-gray-dark icon-center"><i
                                    class="fa fa-circle bg-white text-gray-dark"></i></div>
                        </div>

                        <div class="m-b50">
                            <!-- TITLE START -->
                            <div class="section-head">
                                <div class="mt-separator-outer separator-left">
                                    <div class="mt-separator">
                                        <h2 class="text-black text-uppercase sep-line-one "><span
                                                class="font-weight-300 text-primary">Outline</span> Style</h2>
                                    </div>
                                </div>
                            </div>
                            <!-- TITLE END -->
                            <button class="m-b15 site-button outline white text-uppercase m-r15" type="button">Button
                                white</button>
                            <button class="m-b15 site-button outline orange text-uppercase m-r15" type="button">Button
                                Orange</button>
                            <button class="m-b15 site-button outline red text-uppercase m-r15" type="button">Button
                                Red</button>
                            <button class="m-b15 site-button outline brown text-uppercase m-r15" type="button">Button
                                Brown</button>
                            <button class="m-b15 site-button outline yellow text-uppercase m-r15" type="button">Button
                                Yellow</button>
                            <button class="m-b15 site-button outline purple text-uppercase m-r15" type="button">Button
                                Purpal</button>
                            <button class="m-b15 site-button outline black text-uppercase m-r15" type="button">Button
                                Black</button>
                            <button class="m-b15 site-button outline gray text-uppercase m-r15" type="button">Button
                                Gray</button>
                            <button class="m-b15 site-button outline pink text-uppercase m-r15" type="button">Button
                                Pink</button>
                            <button class="m-b15 site-button outline blue text-uppercase m-r15" type="button">Button
                                Blue</button>
                            <button class="m-b15 site-button outline green text-uppercase m-r15" type="button">Button
                                Green</button>


                        </div>

                        <div class="m-b10">
                            <div class="mt-divider bg-gray-dark text-gray-dark icon-center"><i
                                    class="fa fa-circle bg-white text-gray-dark"></i></div>
                        </div>

                        <div class="m-b50">
                            <!-- TITLE START -->
                            <div class="section-head">
                                <div class="mt-separator-outer separator-left">
                                    <div class="mt-separator">
                                        <h2 class="text-black text-uppercase sep-line-one "><span
                                                class="font-weight-300 text-primary">With</span> Icon</h2>
                                    </div>
                                </div>
                            </div>
                            <!-- TITLE END -->
                            <button class="m-b15 site-button text-uppercase black m-r5" type="button"><i
                                    class="fa fa-leaf"></i> <span class="site-button-inr">Button Black </span></button>
                            <button class="m-b15 site-button text-uppercase gray m-r5" type="button"><i
                                    class="fa fa-leaf"></i> <span class="site-button-inr">Button Gray </span></button>
                            <button class="m-b15 site-button text-uppercase pink m-r5" type="button"><i
                                    class="fa fa-leaf"></i> <span class="site-button-inr">Button Pink </span></button>
                            <button class="m-b15 site-button text-uppercase blue m-r5" type="button"><i
                                    class="fa fa-leaf"></i> <span class="site-button-inr">Button Blue </span></button>
                            <button class="m-b15 site-button text-uppercase green m-r5" type="button"><i
                                    class="fa fa-leaf"></i> <span class="site-button-inr">Button Green </span></button>
                            <button class="m-b15 site-button text-uppercase white m-r5" type="button"><i
                                    class="fa fa-leaf"></i> <span class="site-button-inr">Button white </span></button>
                            <button class="m-b15 site-button text-uppercase yellow m-r5" type="button"><i
                                    class="fa fa-leaf"></i> <span class="site-button-inr">Button Yellow </span></button>
                            <button class="m-b15 site-button text-uppercase purple m-r5" type="button"><i
                                    class="fa fa-leaf"></i> <span class="site-button-inr">Button Purpal </span></button>
                            <button class="m-b15 site-button text-uppercase orange m-r5" type="button"><i
                                    class="fa fa-leaf"></i> <span class="site-button-inr">Button Orange </span></button>
                            <button class="m-b15 site-button text-uppercase red  m-r5" type="button"><i
                                    class="fa fa-leaf"></i> <span class="site-button-inr">Button Red </span></button>
                            <button class="m-b15 site-button text-uppercase brown m-r5" type="button"><i
                                    class="fa fa-leaf"></i> <span class="site-button-inr">Button Brown </span></button>

                        </div>

                        <div class="m-b10">
                            <div class="mt-divider bg-gray-dark text-gray-dark icon-center"><i
                                    class="fa fa-circle bg-white text-gray-dark"></i></div>
                        </div>

                        <div class="m-b0">

                            <!-- TITLE START -->
                            <div class="section-head">
                                <div class="mt-separator-outer separator-left">
                                    <div class="mt-separator">
                                        <h2 class="text-black text-uppercase sep-line-one "><span
                                                class="font-weight-300 text-primary">Bootstrap</span> Button</h2>
                                    </div>
                                </div>
                            </div>
                            <!-- TITLE END -->


                            <button class="m-b15 btn btn-default text-uppercase" type="button">Button Default</button>
                            <button class="m-b15 btn btn-primary text-uppercase" type="button">Button Primary</button>
                            <button class="m-b15 btn btn-custom text-uppercase" type="button">Button Custom</button>
                            <button class="m-b15 btn btn-success text-uppercase" type="button">Button Success</button>
                            <button class="m-b15 btn btn-info text-uppercase" type="button">Button Info</button>
                            <button class="m-b15 btn btn-warning text-uppercase" type="button">Button Warning</button>
                            <button class="m-b15 btn btn-danger text-uppercase" type="button">Button Danger</button>
                            <button class="m-b15 btn btn-link text-uppercase" type="button">Button Link</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>

    <!-- SECTION CONTENT END -->

    <!-- CONTENT END -->

    <!-- FOOTER START -->
    <app-footer></app-footer>
    <!-- FOOTER END -->

</div>

<app-loader></app-loader>