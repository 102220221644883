import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-service-box1',
  templateUrl: './service-box1.component.html',
  styleUrls: ['./service-box1.component.css']
})
export class ServiceBox1Component {

  @Input() data: any;

  constructor() { }

}
