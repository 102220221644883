<div class="page-wraper">

    <!-- HEADER START -->
    <app-header></app-header>
    <!-- HEADER END -->

    <!-- CONTENT START -->
    <div class="page-content">

        <!-- INNER PAGE BANNER -->
        <app-banner [data]="banner"></app-banner>
        <!-- INNER PAGE BANNER END -->

        <!-- SECTION CONTENTG START -->
        <div class="section-full p-tb80 inner-page-padding">
            <div class="container">
                <div class="row">
                    <div class="col-md-3 col-sm-4">
                        <app-left-sidebar1></app-left-sidebar1>
                    </div>
                    <div class="col-md-9 col-sm-8">


                        <!-- TITLE START -->
                        <div class="section-head">
                            <div class="mt-separator-outer separator-left">
                                <div class="mt-separator">
                                    <h2 class="text-black text-uppercase sep-line-one "><span
                                            class="font-weight-300 text-primary">Model</span> Popup</h2>
                                </div>
                            </div>
                        </div>
                        <!-- TITLE END -->
                        <div class="row">
                            <div class="button-model col-md-3 col-sm-6">
                                <!-- TRIGGER THE MODAL WITH A BUTTON -->
                                <a class="m-b30" href="javascript:void(0);" data-toggle="modal" data-target="#Default-Modal">
                                    <img src="assets/images/gallery/pic1.jpg" alt="">
                                    <div class="text-center p-t20 m-b30">
                                        <h4> Default-Modal</h4>
                                    </div>
                                </a>
                                <!-- MODAL -->
                                <div id="Default-Modal" class="modal fade" role="dialog">
                                    <div class="modal-dialog">
                                        <!-- MODAL CONTENT-->
                                        <div class="modal-content">
                                            <div class="modal-header bg-secondary">
                                                <button type="button" class="close"
                                                    data-dismiss="modal">&times;</button>
                                                <h4 class="modal-title text-white">Modal Header</h4>
                                            </div>
                                            <div class="modal-body">
                                                <p>We denounce with righteous indignation and dislike men who are
                                                    beguiled and demoralized by the charms of pleasures that moment, so
                                                    blinded by desires, that they cannot foresee indignations some
                                                    dislike mens who are so beguiled demoralized.</p>
                                            </div>
                                            <div class="modal-footer">
                                                <button type="button"
                                                    class="site-button btn-effect button-sm text-uppercase letter-spacing-2"
                                                    data-dismiss="modal">Close</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="button-model col-md-3 col-sm-6">
                                <!-- TRIGGER THE MODAL WITH A BUTTON -->
                                <a class="m-b30" href="javascript:void(0);" data-toggle="modal" data-target="#Small-Modal">
                                    <img src="assets/images/gallery/pic2.jpg" alt="">
                                    <div class="text-center p-t20 m-b30">
                                        <h4> Small-Modal</h4>
                                    </div>
                                </a>
                                <!-- MODAL -->
                                <div id="Small-Modal" class="modal fade" role="dialog">
                                    <div class="modal-dialog modal-sm">
                                        <!-- MODAL CONTENT -->
                                        <div class="modal-content">
                                            <div class="modal-header bg-secondary">
                                                <button type="button" class="close"
                                                    data-dismiss="modal">&times;</button>
                                                <h4 class="modal-title text-white">Modal Header</h4>
                                            </div>
                                            <div class="modal-body">
                                                <p>We denounce with righteous indignation and dislike men who are
                                                    beguiled and demoralized by the charms of pleasures that moment, so
                                                    blinded by desires, that they cannot foresee indignations some
                                                    dislike mens who are so beguiled demoralized..</p>
                                            </div>
                                            <div class="modal-footer">
                                                <button type="button"
                                                    class="site-button btn-effect button-sm text-uppercase letter-spacing-2"
                                                    data-dismiss="modal">Close</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="button-model col-md-3 col-sm-6">
                                <!-- TRIGGER THE MODAL WITH A BUTTON -->
                                <a class="m-b30" href="javascript:void(0);" data-toggle="modal" data-target="#large-Modal">
                                    <img src="assets/images/gallery/pic3.jpg" alt="">
                                    <div class="text-center p-t20 m-b30">
                                        <h4> Large-Modal</h4>
                                    </div>
                                </a>
                                <!-- MODAL -->
                                <div id="large-Modal" class="modal fade" role="dialog">
                                    <div class="modal-dialog modal-lg">
                                        <!-- MODAL CONTENT -->
                                        <div class="modal-content">
                                            <div class="modal-header bg-secondary">
                                                <button type="button" class="close"
                                                    data-dismiss="modal">&times;</button>
                                                <h4 class="modal-title text-white">Modal Header</h4>
                                            </div>
                                            <div class="modal-body">
                                                <p>We denounce with righteous indignation and dislike men who are
                                                    beguiled and demoralized by the charms of pleasures that moment, so
                                                    blinded by desires, that they cannot foresee indignations some
                                                    dislike mens who are so beguiled demoralized.</p>
                                            </div>
                                            <div class="modal-footer">
                                                <button type="button"
                                                    class="site-button btn-effect button-sm text-uppercase letter-spacing-2"
                                                    data-dismiss="modal">Close</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="button-model col-md-3 col-sm-6">
                                <!-- TRIGGER THE MODAL WITH A BUTTON -->
                                <a class="m-b30" href="javascript:void(0);" data-toggle="modal" data-target="#with-form">
                                    <img src="assets/images/gallery/pic4.jpg" alt="">
                                    <div class="text-center p-t20 m-b30">
                                        <h4> With-form</h4>
                                    </div>
                                </a>
                                <!-- MODAL -->
                                <div id="with-form" class="modal fade" role="dialog">
                                    <div class="modal-dialog">
                                        <!-- Modal content-->
                                        <div class="modal-content">
                                            <div class="modal-header bg-secondary">
                                                <button type="button" class="close"
                                                    data-dismiss="modal">&times;</button>
                                                <h4 class="modal-title text-white">Modal Header</h4>
                                            </div>
                                            <div class="modal-body">
                                                <form id="demo-form" class="form-horizontal mb-lg" novalidate>
                                                    <div class="form-group mt-lg">
                                                        <label class="col-sm-3 control-label">Name</label>
                                                        <div class="col-sm-9">
                                                            <input name="name" class="form-control"
                                                                placeholder="Type your name..." required type="text">
                                                        </div>
                                                    </div>
                                                    <div class="form-group">
                                                        <label class="col-sm-3 control-label">Email</label>
                                                        <div class="col-sm-9">
                                                            <input name="email" class="form-control"
                                                                placeholder="Type your email..." required type="email">
                                                        </div>
                                                    </div>
                                                    <div class="form-group">
                                                        <label class="col-sm-3 control-label">URL</label>
                                                        <div class="col-sm-9">
                                                            <input name="url" class="form-control"
                                                                placeholder="Type an URL..." type="url">
                                                        </div>
                                                    </div>
                                                    <div class="form-group">
                                                        <label class="col-sm-3 control-label">Comment</label>
                                                        <div class="col-sm-9">
                                                            <textarea rows="5" class="form-control"
                                                                placeholder="Type your comment..." required></textarea>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                            <div class="modal-footer">
                                                <button type="button"
                                                    class="site-button btn-effect text-uppercase button-sm letter-spacing-2 m-r15"
                                                    data-dismiss="modal">Close</button>
                                                <button type="button"
                                                    class="site-button btn-effect text-uppercase button-sm letter-spacing-2">Save
                                                    Changes </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <!-- SECTION CONTENT END -->

    </div>
    <!-- CONTENT END -->

    <!-- FOOTER START -->
    <app-footer></app-footer>
    <!-- FOOTER END -->

</div>

<app-loader></app-loader>