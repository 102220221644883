import { Component } from '@angular/core';

@Component({
  selector: 'app-error404',
  templateUrl: './error404.component.html',
  styleUrls: ['./error404.component.css']
})
export class Error404Component{

  constructor() { }

  banner = {
    image: "assets/images/banner/4.jpg",
    title: "Page Not Found",
    page: "Error Page"
  }
}
