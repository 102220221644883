import { Component, Input, OnInit } from '@angular/core';
import { Our_mission } from 'src/app/core/Services/proxy.service';

@Component({
  selector: 'app-vision',
  templateUrl: './vision.component.html',
  styleUrls: ['./vision.component.css']
})
export class VisionComponent {
  @Input() data: Our_mission;
  constructor() { }

}
