<div class="image-effect-one hover-shadow">
    <img src="{{data.image}}" alt=""/>
    <div class="figcaption bg-dark">
        <h4>{{data.title}}</h4>
        <p>{{data.subtitle}}</p>
        <a routerLink="/project-detail"><i class="link-plus bg-primary"></i></a>
    </div>
    <a *ngIf="zoom == '1'" class="mfp-link" href="{{data.image}}">
        <i class="fa fa-arrows-alt"></i>
    </a>
    
</div>