<div class="section-full p-t80 p-b80 bg-white inner-page-padding">
    <div class="container">
        <!-- TITLE START -->
        <div *ngIf="data == '1'" class="section-head no-margin ">
            <div class="mt-separator-outer separator-left">
                <div class="mt-separator">
                    <h2 class="text-uppercase sep-line-one "><span class="font-weight-300 text-primary">About</span>
                        Company</h2>
                </div>
            </div>
        </div>
        <!-- TITLE END -->
        <div class="section-content ">
            <div class="row">
                <div *ngIf="!hideAboutImage" class="col-md-5 col-sm-12">
                    <div class="m-about">
                        <div class="item about-image-container">
                            <a><img src="{{aboutImageURL}}"></a>
                        </div>
                    </div>
                </div>
                <div class="col-md-7 col-sm-12">
                    <div class="m-about-containt text-black">
                        <div class="m-about-years bg-moving"
                            style="background-image:url(assets/images/background/line.png);">
                            <span class="text-primary large-title">{{ExperienceYears}}</span>
                            <span class="large-title-info">Year{{ExperienceYears==1?'':'s'}} of Experience</span>
                        </div>
                        <h3 class="font-weight-600" style="margin-top: 30px;">Unique. Scalable. Efficient.</h3>
                        <p [innerText]="data.description"></p>
                        <div class="author-info p-t20"
                            style="display:flex; align-items:center; justify-content: space-between">

                            <!-- <div class="author-signature">
                                <img src="{{data.sign}}" alt="Signature" width="150" />
                            </div>

                            <div class="author-name">
                                <h4 class="m-t0">{{data.author}}</h4>
                                <p>{{data.designation}}</p>
                            </div> -->

                            <a data-toggle="modal" data-target="#with-form"
                                class="site-button btn-effect m-b15" style="margin-top: 0px !important;"><span>Get in touch</span></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>