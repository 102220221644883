import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent {

  @Input() page: any;
  @Input() data: any;
  @Output() scrollEvent = new EventEmitter<string>();

  Today;
  azuresoftLink = 'https://azuresoft.ae';

  constructor() {
    this.Today = new Date().getFullYear();
  }

  emitDestination(dest): void {
    this.scrollEvent.emit(dest);
  }

  goToLink(link): void {
    window.open(link);
  }
}
