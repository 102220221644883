<div class="half-blocks">
    <div class="mt-icon-box-wraper center m-b30">
        <div class="half-block-content icon-count-2 p-a30 p-tb50">
            <span class="icon-count-number">{{data.serial}}</span>
            <div class="mt-icon-box-lg radius bg-gray m-b20 ">
                <span class="icon-cell"><img src="{{data.icon}}" alt=""></span>
            </div>
            <div class="icon-content">
                <h4 class="mt-tilte m-b25">{{data.title}}</h4>
                <p>{{data.description}}</p>
                <a routerLink="/about" class="site-button-link" data-hover="Read More">Read More <i class="fa fa-angle-right arrow-animation"></i></a>
            </div>
        </div>
        <div class="bg-cover  half-blocks-bg" style="background-image:url({{data.background}})"></div>
    </div>
</div>