<div class="mt-icon-box-wraper p-a25 m-b30 onhover-box-shadow {{data.background}} center">
    <div class="mt-icon-box-sm inline-icon text-primary  m-b20 radius bg-secondary  ">
        <span class="icon-cell text-primary"><i class="{{data.icon}}"></i></span>
    </div>
    <div class="icon-content {{data.text}}">
        <h4 class="mt-tilte text-uppercase font-weight-600 m-b20">{{data.title}}</h4>
        <p>{{data.description}}
        </p>
        <a routerLink="/project-detail" class="site-button-link {{data.link}}" data-hover="Read More">Read More <i
                class="fa fa-angle-right arrow-animation"></i></a>
    </div>
</div>