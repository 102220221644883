<div class="section-full  our-service-wrap  mobile-page-padding square_shape2">
    <div class="section-content">
        <div class="Service-half-top p-t80 bg-dark bg-moving"
            style="background-image:url(assets/images/background/bg-6.png); place-content: center;">
            <div class="container">
                <!-- TITLE START -->
                <div class="section-head left text-white">
                    <div class="mt-separator-outer separator-left">
                        <div class="mt-separator">
                            <h2 class="text-white text-uppercase sep-line-one "><span
                                    class="font-weight-300 text-primary">Our</span> Services</h2>
                        </div>
                    </div>
                </div>
                <!-- TITLE END -->
            </div>


            <div class="container">
                <div class="row">
                    <div style="display: flex;" class="col-md-4 col-sm-6" *ngFor="let object of data">
                        <app-service-box1 style="flex: 1 1 auto; display: flex; justify-content: center;" [data]="object"></app-service-box1>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>