import { Injectable } from '@angular/core';
import { CommonService } from './common.service';
import { Params_Send_Mail, Proxy, Website_Data } from './proxy.service';

@Injectable({
  providedIn: 'root'
})
export class WebsiteManagementService {

  constructor(
    private proxy: Proxy,
    private CmSvc: CommonService
  ) {
  }
  Get_Website_Data(): Promise<Website_Data> {
    return new Promise((resolve, reject) => {
      this.proxy.Get_Website_Data().subscribe(result => {
        resolve(result);
      });

    });
  }

  Send_Mail(i_Params_Send_Mail: Params_Send_Mail): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.proxy.Send_Mail(i_Params_Send_Mail).subscribe(result => {
        this.CmSvc.ShowMessage('Success');
        resolve(true);
      });
    });
  }
}
