<div class="section-full mobile-page-padding p-tb80 overlay-wraper bg-cover bg-center" style="background-image:url(assets/images/background/bg-1.jpg)">
    <div class="overlay-main bg-primary opacity-08"></div>
    <div class="container">

            <div class="section-content">
                <div class="call-us-section text-center">
                    <h4 class="m-b15">Let's work together</h4>
                    <!-- <h2 class="call-us-number m-b15 m-b0">{{data.phone_extension}} {{data.phone}}</h2> -->
                    <h2 class="call-us-number m-b15 m-b0">{{data.email}}</h2>
                    <h4 class="call-us-address m-t0 m-b20" [innerText]="data.address" style="line-height: 35px;"></h4>
                    <a [href]="'MAILTO:'+data.email" style="margin-top:8px;" class="site-button-secondary btn-effect bg-dark">Send an Email</a>
                </div>
            </div>
        
    </div>
</div>