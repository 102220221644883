<div class="section-full mobile-page-padding mission-outer-section  p-t60 p-b30 bg-gray bg-no-repeat bg-right-center"
    style="background-size: cover;" [ngStyle]="{'background-image': 'url(assets/images/background/bg.png)'}">
    <div class="section-content">
        <div class="container">
            <div class="section-head left" style="margin-bottom: 0px;">
                <div class="mt-separator-outer separator-left">
                    <div class="mt-separator">
                        <h2 class="text-uppercase sep-line-one "><span class="font-weight-300 text-primary">Our</span>
                            Vision</h2>
                    </div>
                </div>
            </div>
            <div class="mission-left m-b30 bg-no-repeat bg-bottom-left"
                style="height: 250px; display: flex; align-items: center;">
                <h3 class="m-t0"><span class="font-weight-300">{{data.VISION}}</span></h3>
            </div>
        </div>
    </div>
</div>