<div class="image-effect-two hover-shadow">
    <img src="{{data.image}}" alt=""/>
    <div class="figcaption">
        <h4 class="mt-tilte">{{data.title}}</h4>
        <p>{{data.description}}
        </p>
        <a routerLink="/project-detail" class="read-more site-button btn-effect">Read More</a>
        <a class="mfp-link" href="{{data.linkImage}}">
            <i class="fa fa-arrows-alt"></i>
        </a>
    </div>
</div>