<div class="page-wraper">

    <!-- HEADER START -->
    <app-header></app-header>
    <!-- HEADER END -->

    <!-- CONTENT START -->
    <div class="page-content">

        <!-- INNER PAGE BANNER -->
        <app-banner [data]="banner"></app-banner>
        <!-- INNER PAGE BANNER END -->

        <!-- SECTION CONTENTG START -->
        <div class="section-full p-tb80 inner-page-padding">
            <div class="container">
                <div class="row">
                    <div class="col-md-3 col-sm-4">
                        <app-left-sidebar1></app-left-sidebar1>
                    </div>
                    <div class="col-md-9 col-sm-8">
                        <div class="section-content">
                            <div class="m-b50">

                                <!-- TITLE START -->
                                <div class="section-head">
                                    <div class="mt-separator-outer separator-left">
                                        <div class="mt-separator">
                                            <h2 class="text-black text-uppercase sep-line-one "><span
                                                    class="font-weight-300 text-primary">background</span> Tabs</h2>
                                        </div>
                                    </div>
                                </div>
                                <!-- TITLE END -->

                                <!-- TABS DEFAULT NAV LEFT -->
                                <div class="mt-tabs vertical bg-tabs">
                                    <ul class="nav nav-tabs">
                                        <li class="active"><a data-toggle="tab" href="#web-design-15">Home</a></li>
                                        <li><a data-toggle="tab" href="#graphic-design-15">Profile</a></li>
                                        <li><a data-toggle="tab" href="#developement-15">Massages</a></li>
                                    </ul>
                                    <div class="tab-content">

                                        <div id="web-design-15" class="tab-pane active">
                                            <p class="m-b0">There are many variations of passages of to repeat
                                                predefined chunks as necessary, making this the first true generator on
                                                the Internet. It uses and dictionary of over 200 Latin words, there are many variations of passages of to repeat
                                                predefined chunks as necessary, making this the first true generator on
                                                the Internet. </p>
                                        </div>

                                        <div id="graphic-design-15" class="tab-pane">
                                            <p class="m-b0">All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this thefirst true generator on the Internet.</p>
                                        </div>

                                        <div id="developement-15" class="tab-pane">
                                            <p class="m-b0">Making this the first true generator on
                                                the Internet. It uses a dictionary of over 200 Latin words,</p>
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <div class="m-b50">

                                <!-- TITLE START -->
                                <div class="section-head">
                                    <div class="mt-separator-outer separator-left">
                                        <div class="mt-separator">
                                            <h2 class="text-black text-uppercase sep-line-one "><span
                                                    class="font-weight-300 text-primary">Tabs left with</span>
                                                background</h2>
                                        </div>
                                    </div>
                                </div>
                                <!-- TITLE END -->

                                <!-- TAB DEFAULT WITH NAV BG -->
                                <div class="section-content">
                                    <div class="mt-tabs bg-tabs">
                                        <ul class="nav nav-tabs">
                                            <li class="active"><a data-toggle="tab" href="#web-design-13">Home</a></li>
                                            <li><a data-toggle="tab" href="#graphic-design-13">Profile</a></li>
                                            <li><a data-toggle="tab" href="#developement-13">Massages</a></li>
                                        </ul>
                                        <div class="tab-content">

                                            <div id="web-design-13" class="tab-pane active">
                                                <p class="m-b0">There are many variations of passages of to repeat
                                                    predefined chunks as necessary, making this the first true generator on
                                                    the Internet. It uses a dictionary of over 200 Latin words</p>
                                            </div>

                                            <div id="graphic-design-13" class="tab-pane">
                                                <p class="m-b0">All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this thefirst true generator on the Internet.</p>
                                            </div>

                                            <div id="developement-13" class="tab-pane">
                                                <p class="m-b0">Making this the first true generator on
                                                    the Internet. It uses a dictionary of over 200 Latin words,</p>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="m-b50">

                                <!-- TITLE START -->
                                <div class="section-head">
                                    <div class="mt-separator-outer separator-left">
                                        <div class="mt-separator">
                                            <h2 class="text-black text-uppercase sep-line-one "><span
                                                    class="font-weight-300 text-primary">Default</span> Tabs</h2>
                                        </div>
                                    </div>
                                </div>
                                <!-- TITLE END -->

                                <!-- TAB DEFAULT -->
                                <div class="section-content">
                                    <div class="mt-tabs tabs-default">
                                        <ul class="nav nav-tabs">
                                            <li class="active"><a data-toggle="tab" href="#web-design-1">Home</a></li>
                                            <li><a data-toggle="tab" href="#graphic-design-1">Profile</a></li>
                                            <li><a data-toggle="tab" href="#developement-1">Massages</a></li>
                                        </ul>
                                        <div class="tab-content">

                                            <div id="web-design-1" class="tab-pane active">
                                                <p class="m-b0">There are many variations of passages of to repeat
                                                    predefined chunks as necessary, making this the first true generator on
                                                    the Internet. It uses a dictionary of over 200 Latin words</p>
                                            </div>

                                            <div id="graphic-design-1" class="tab-pane">
                                                <p class="m-b0">All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this thefirst true generator on the Internet.</p>
                                            </div>

                                            <div id="developement-1" class="tab-pane">
                                                <p class="m-b0">Making this the first true generator on
                                                    the Internet. It uses a dictionary of over 200 Latin words,</p>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="m-b0">

                                <!-- TITLE START -->
                                <div class="section-head">
                                    <div class="mt-separator-outer separator-left">
                                        <div class="mt-separator">
                                            <h2 class="text-black text-uppercase sep-line-one "><span
                                                    class="font-weight-300 text-primary">Tabs Defaul</span> nav left
                                            </h2>
                                        </div>
                                    </div>
                                </div>
                                <!-- TITLE END -->

                                <!-- TAB DEFAULT -->
                                <div class="mt-tabs vertical tabs-default">

                                    <ul class="nav nav-tabs">
                                        <li class="active"><a data-toggle="tab" href="#web-design-3">Home</a></li>
                                        <li><a data-toggle="tab" href="#graphic-design-3">Profile</a></li>
                                        <li><a data-toggle="tab" href="#developement-3">Massages</a></li>
                                    </ul>

                                    <div class="tab-content">

                                        <div id="web-design-3" class="tab-pane active">
                                            <p class="m-b0">There are many variations of passages of to repeat
                                                predefined chunks as necessary, making this the first true generator on
                                                the Internet. It uses and dictionary of over 200 Latin words there are many variations of passages of to repeat
                                                predefined chunks as necessary, making this the first true generator on
                                                the Internet.</p>
                                        </div>

                                        <div id="graphic-design-3" class="tab-pane">
                                            <p class="m-b0">All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this thefirst true generator on the Internet.</p>
                                        </div>

                                        <div id="developement-3" class="tab-pane">
                                            <p class="m-b0">Making this the first true generator on
                                                the Internet. It uses a dictionary of over 200 Latin words,</p>
                                        </div>

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- SECTION CONTENT END -->

    </div>
    <!-- CONTENT END -->

    <!-- FOOTER START -->
    <app-footer></app-footer>
    <!-- FOOTER END -->

</div>

<app-loader></app-loader>