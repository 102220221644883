<div class="mt-icon-box-wraper p-a30 center m-b30 box-shadow bg-white">
    <div class="mt-icon-box-sm inline-icon text-primary  m-b20 radius bg-primary   bg-moving"
        style="background-image:url(assets/images/background/line.png)">
        <span class="icon-cell text-secondary"><i class="{{data.icon}}"></i></span>
    </div>
    <div class="icon-content">
        <h4 class="mt-tilte text-uppercase font-weight-600 m-b20">{{data.title}}</h4>
        <div class="button-model col-md-3 col-sm-6">
            <!-- TRIGGER THE MODAL WITH A BUTTON -->
            <a class="m-b30" href="javascript:void(0);" data-toggle="modal" data-target="#Small-Modal">
                <img src="assets/images/gallery/pic2.jpg" alt="">
                <div class="text-center p-t20 m-b30">
                    <h4> Small-Modal</h4>
                </div>
            </a>
            <!-- MODAL -->
            <div id="Small-Modal" class="modal fade" role="dialog">
                <div class="modal-dialog modal-sm">
                    <!-- MODAL CONTENT -->
                    <div class="modal-content">
                        <div class="modal-header bg-secondary">
                            <button type="button" class="close"
                                data-dismiss="modal">&times;</button>
                            <h4 class="modal-title text-white">Modal Header</h4>
                        </div>
                        <div class="modal-body">
                            <p>We denounce with righteous indignation and dislike men who are
                                beguiled and demoralized by the charms of pleasures that moment, so
                                blinded by desires, that they cannot foresee indignations some
                                dislike mens who are so beguiled demoralized..</p>
                        </div>
                        <div class="modal-footer">
                            <button type="button"
                                class="site-button btn-effect button-sm text-uppercase letter-spacing-2"
                                data-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <a routerLink="/services" class="site-button-link" data-hover="Read More">Read More <i
                class="fa fa-angle-right arrow-animation"></i></a>
    </div>
</div>