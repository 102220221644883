<div class="mt-icon-box-wraper bdr-l-1 bdr-solid bdr-gray">
    <div class="relative  p-a30">
        
        <div class="icon-md inline-icon m-b15 text-primary ">
            <span class="icon-cell"><img src="{{data.icon}}" alt=""></span>
        </div>
        <div class="icon-content">
            <h4 class="mt-tilte m-b25">{{data.title}}</h4>
            <p>{{data.description}}</p>
            <a routerLink="/services" class="site-button-link" data-hover="Read More">Read More <i class="fa fa-angle-right arrow-animation"></i></a>
        </div>
    </div>
</div>