<div class="page-wraper">  
      	
    <!-- HEADER START -->
    <app-header></app-header>
    <!-- HEADER END -->>
    
    <!-- CONTENT START -->
    <div class="page-content">
    
        <!-- INNER PAGE BANNER -->
        <app-banner [data]="banner"></app-banner>
        <!-- INNER PAGE BANNER END -->
         
        <!-- SECTION CONTENTG START -->
        <div class="section-full p-tb80 bg-white inner-page-padding">
            <!-- LOCATION BLOCK-->
            
                    <div class="container">
                        <div class="row">
                            <div class="col-md-3 col-sm-4">
                                <app-left-sidebar1></app-left-sidebar1>
                            </div>
                            <div class="col-md-9 col-sm-8">   
                                    <!-- TITLE START -->
                                    <div class="section-head">
                                        <div class="mt-separator-outer separator-left">
                                            <div class="mt-separator">
                                                <h2 class="text-black text-uppercase sep-line-one "><span class="font-weight-300 text-primary">Icon</span> box</h2>
                                            </div>
                                        </div>
                                    </div>                   
                                    <!-- TITLE END -->                                    
                                                 
                                <!-- ICON BOX -->
                                <div class="section-content m-b0">
                                    <!-- ICON BOX -->
                                    <div class="section-content m-b50">
                                        <div class="row">
                                        
                                            <div class="col-md-6 col-sm-6 m-b30">
                                                <div class="mt-icon-box-wraper bx-style-1 p-a30 center bg-white block-shadow">
                                                    <div class="mt-icon-box-sm bg-secondary m-b20   ">
                                                        <span class="icon-cell text-primary"><i class="fa fa-trophy"></i></span>
                                                    </div>
                                                    <div class="icon-content">
                                                        <h4 class="mt-tilte">Icon square</h4>
                                                        <p>From complete turn key to project manager. Leave the building to the professionals</p>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            <div class="col-md-6 col-sm-6 m-b30">
                                                <div class="mt-icon-box-wraper bx-style-1 p-a30 center bg-white hover-shadow">
                                                    <div class="mt-icon-box-sm radius bg-secondary m-b20  ">
                                                        <span class="icon-cell text-primary"><i class="fa fa-trophy"></i></span>
                                                    </div>
                                                    <div class="icon-content">
                                                        <h4 class="mt-tilte">Icon circle</h4>
                                                        <p>From complete turn key to project manager. Leave the building to the professionals.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            <div class="col-md-6 col-sm-6 m-b30">
                                                <div class="mt-icon-box-wraper bx-style-1 p-a30 center bg-white hover-shadow">
                                                    <div class="mt-icon-box-sm text-secondary bg-white radius bdr-2 bdr-solid bdr-black m-b20 ">
                                                        <span class="icon-cell text-secondary"><i class="fa fa-trophy"></i></span>
                                                    </div>
                                                    <div class="icon-content">
                                                        <h4 class="mt-tilte">Border icon</h4>
                                                        <p>From complete turn key to project manager. Leave the building to the professionals.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            <div class="col-md-6 col-sm-6 m-b30">
                                                <div class="mt-icon-box-wraper bx-style-1 p-a30 center bg-white hover-shadow">
                                                    <div class="icon-xl m-b20 ">
                                                        <span class="icon-cell text-primary"><i class="fa fa-trophy"></i></span>
                                                    </div>
                                                    <div class="icon-content">
                                                        <h4 class="mt-tilte">Normal icon center</h4>
                                                        <p>From complete turn key to project manager. Leave the building to the professionals</p>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                        </div>
                                    </div>
                                    
                                    <!-- ICON BOX -->
                                    <div class="section-content m-b50">
                                        <div class="row">
                                        
                                            <div class="col-md-6 col-sm-6 m-b30">
                                                <div class="mt-icon-box-wraper bx-style-1 p-a20 left bg-white block-shadow">
                                                    <div class="mt-icon-box-xs bg-secondary ">
                                                        <span class="icon-cell text-primary"><i class="fa fa-trophy"></i></span>
                                                    </div>
                                                    <div class="icon-content">
                                                        <h4 class="mt-tilte">Square left</h4>
                                                        <p>From complete turn key to project manager. Leave the building to the professionals</p>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            <div class="col-md-6 col-sm-6 m-b30">
                                                <div class="mt-icon-box-wraper bx-style-1 p-a20 right bg-white hover-shadow">
                                                    <div class="mt-icon-box-xs bg-secondary ">
                                                        <span class="icon-cell text-primary"><i class="fa fa-trophy"></i></span>
                                                    </div>
                                                    <div class="icon-content">
                                                        <h4 class="mt-tilte">Square right</h4>
                                                        <p>From complete turn key to project manager. Leave the building to the professionals</p>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            <div class="col-md-6 col-sm-6 m-b30">
                                                <div class="mt-icon-box-wraper bx-style-1 p-a20 left bg-white hover-shadow">
                                                    <div class="mt-icon-box-xs radius bg-secondary ">
                                                        <span class="icon-cell text-primary"><i class="fa fa-trophy"></i></span>
                                                    </div>
                                                    <div class="icon-content">
                                                        <h4 class="mt-tilte">Circle left</h4>
                                                        <p>From complete turn key to project manager. Leave the building to the professionals</p>
                                                    </div>
                                                </div>
                                            </div>                            
                                            
                                            <div class="col-md-6 col-sm-6 m-b30">
                                                <div class="mt-icon-box-wraper bx-style-1 p-a20 right bg-white hover-shadow">
                                                    <div class="mt-icon-box-xs radius bg-secondary ">
                                                        <span class="icon-cell text-primary"><i class="fa fa-trophy"></i></span>
                                                    </div>
                                                    <div class="icon-content">
                                                        <h4 class="mt-tilte">Circle right</h4>
                                                        <p>From complete turn key to project manager. Leave the building to the professionals</p>
                                                    </div>
                                                </div>
                                            </div>                                        
                                            
                                        </div>
                                    </div>                                    
                                    <div class="row">
                                        <div class="col-md-6 col-sm-6 m-b30">
                                            <div class="mt-icon-box-wraper bx-style-1 p-a20 left bg-white block-shadow">
                                                <div class="mt-icon-box-xs text-secondary bg-white radius bdr-1  bdr-solid ">
                                                    <span class="icon-cell text-secondary"><i class="fa fa-trophy"></i></span>
                                                </div>
                                                <div class="icon-content">
                                                    <h4 class="mt-tilte">Border left</h4>
                                                    <p>From complete turn key to project manager. Leave the building to the professionals</p>
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <div class="col-md-6 col-sm-6 m-b30">
                                            <div class="mt-icon-box-wraper bx-style-1 p-a20 right bg-white hover-shadow">
                                                <div class="mt-icon-box-xs text-secondary bg-white radius bdr-1  bdr-solid ">
                                                    <span class="icon-cell text-secondary"><i class="fa fa-trophy"></i></span>
                                                </div>
                                                <div class="icon-content">
                                                    <h4 class="mt-tilte">Border right</h4>
                                                    <p>From complete turn key to project manager. Leave the building to the professionals</p>
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <div class="col-md-6 col-sm-6 m-b30">
                                            <div class="mt-icon-box-wraper bx-style-1 p-a20 left bg-white hover-shadow">
                                                <div class="icon-sm m-b20 ">
                                                    <span class="icon-cell text-secondary"><i class="fa fa-trophy"></i></span>
                                                </div>
                                                <div class="icon-content">
                                                    <h4 class="mt-tilte">Normal left</h4>
                                                    <p>From complete turn key to project manager. Leave the building to the professionals</p>
                                
                                                </div>
                                            </div>
                                        </div>                                
                                        
                                        <div class="col-md-6 col-sm-6 m-b30">
                                            <div class="mt-icon-box-wraper bx-style-1 p-a20 right bg-white hover-shadow">
                                                <div class="icon-sm m-b20 ">
                                                    <span class="icon-cell text-secondary"><i class="fa fa-trophy"></i></span>
                                                </div>
                                                <div class="icon-content">
                                                    <h4 class="mt-tilte">Normal right</h4>
                                                    <p>From complete turn key to project manager. Leave the building to the professionals</p>
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </div>
                                    
                                </div>
                                                    

                             </div>
                        </div>   
                    </div>                    
                                                   
       </div>
        <!-- SECTION CONTENT END -->
        
    </div>
    <!-- CONTENT END -->

    <!-- FOOTER START -->
    <app-footer></app-footer>
    <!-- FOOTER END -->
    
</div>

<app-loader></app-loader>