<div class="page-wraper">

    <!-- HEADER START -->
    <app-header></app-header>
    <!-- HEADER END -->

    <!-- CONTENT START -->
    <div class="page-content">

        <!-- INNER PAGE BANNER -->
        <app-banner [data]="banner"></app-banner>
        <!-- INNER PAGE BANNER END -->

        <!-- SECTION CONTENTG START -->
        <div class="section-full p-tb80 inner-page-padding">
            <div class="container">
                <div class="row">
                    <div class="col-md-3 col-sm-4">
                        <app-left-sidebar1></app-left-sidebar1>
                    </div>
                    <div class="col-md-9 col-sm-8">
                        <div class="m-b50">
                            <!-- TITLE START -->
                            <div class="section-head">
                                <div class="mt-separator-outer separator-left">
                                    <div class="mt-separator">
                                        <h2 class="text-black text-uppercase sep-line-one "><span
                                                class="font-weight-300 text-primary">With</span> Bg-Dark</h2>
                                    </div>
                                </div>
                            </div>
                            <!-- TITLE END -->
                            <!-- ACCORDIAN  BG DARK -->
                            <div class="section-content">
                                <div class="mt-accordion acc-bg-dark" id="accordion9">
                                    <div class="panel mt-panel" *ngFor="let object of accordian">
                                        <div class="acod-head acc-actives">
                                            <h6 class="acod-title">
                                                <a data-toggle="collapse" href="#collapse{{object.serial}}9"
                                                    data-parent="#accordion9" class="{{object.serial != 1 ? 'collapsed' : ''}}">
                                                    {{object.title}}
                                                    <span class="indicator"><i class="fa fa-plus"></i></span>
                                                </a>
                                            </h6>
                                        </div>
                                        <div id="collapse{{object.serial}}9" class="acod-body collapse {{object.serial != 1 ? '' : 'in'}}">
                                            <div class="acod-content p-tb15">{{object.description}}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="m-b50">

                            <!-- TITLE START -->
                            <div class="section-head">
                                <div class="mt-separator-outer separator-left">
                                    <div class="mt-separator">
                                        <h2 class="text-black text-uppercase sep-line-one "><span
                                                class="font-weight-300 text-primary">With</span> Bg-gray</h2>
                                    </div>
                                </div>
                            </div>
                            <!-- TITLE END -->

                            <!-- ACCORDIAN  BACKGROUND GRAY -->
                            <div class="section-content ">
                                <div class="mt-accordion acc-bg-gray" id="accordion5">
                                    <div class="panel mt-panel" *ngFor="let object of accordian">
                                        <div class="acod-head acc-actives">
                                            <h6 class="acod-title">
                                                <a data-toggle="collapse" href="#collapse{{object.serial}}5"
                                                    data-parent="#accordion5" class="{{object.serial != 1 ? 'collapsed' : ''}}">
                                                    {{object.title}}
                                                    <span class="indicator"><i class="fa"></i></span>
                                                </a>
                                            </h6>
                                        </div>
                                        <div id="collapse{{object.serial}}5" class="acod-body collapse {{object.serial != 1 ? '' : 'in'}}">
                                            <div class="acod-content p-tb15">{{object.description}}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div class="m-b0">

                            <!-- TITLE START -->
                            <div class="section-head">
                                <div class="mt-separator-outer separator-left">
                                    <div class="mt-separator">
                                        <h2 class="text-black text-uppercase sep-line-one "><span
                                                class="font-weight-300 text-primary">Default</span> Style</h2>
                                    </div>
                                </div>
                            </div>
                            <!-- TITLE END -->

                            <!-- ACCORDIAN  DEFAULT -->
                            <div class="section-content">
                                <div class="mt-accordion acc-default" id="accordion1">
                                    <div class="panel mt-panel" *ngFor="let object of accordian">
                                        <div class="acod-head acc-actives">
                                            <h6 class="acod-title">
                                                <a data-toggle="collapse" href="#collapse{{object.serial}}1"
                                                    data-parent="#accordion1" class="{{object.serial != 1 ? 'collapsed' : ''}}"><i class="fa fa-address-book"></i>
                                                    {{object.title}}
                                                    <span class="indicator"><i class="fa"></i></span>
                                                </a>
                                            </h6>
                                        </div>
                                        <div id="collapse{{object.serial}}1" class="acod-body collapse {{object.serial != 1 ? '' : 'in'}}">
                                            <div class="acod-content p-tb15">{{object.description}}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- SECTION CONTENT END -->

    </div>
    <!-- CONTENT END -->

    <!-- FOOTER START -->
    <app-footer></app-footer>
    <!-- FOOTER END -->

</div>

<app-loader></app-loader>