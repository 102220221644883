<div class="page-wraper">

    <!-- HEADER START -->
    <app-header></app-header>
    <!-- HEADER END -->

    <!-- CONTENT START -->
    <div class="page-content">

        <!-- INNER PAGE BANNER -->
        <app-banner [data]="banner"></app-banner>
        <!-- INNER PAGE BANNER END -->

        <!-- SECTION CONTENTG START -->
        <div class="section-full p-tb80 inner-page-padding">
            <div class="container">
                <div class="row">
                    <div class="col-md-3 col-sm-4">
                        <app-left-sidebar1></app-left-sidebar1>
                    </div>
                    <div class="col-md-9 col-sm-8">
                        <div class="m-b50">


                            <!-- TITLE START -->
                            <div class="section-head">
                                <div class="mt-separator-outer separator-left">
                                    <div class="mt-separator">
                                        <h2 class="text-black text-uppercase sep-line-one "><span
                                                class="font-weight-300 text-primary">List</span> Unordered</h2>
                                    </div>
                                </div>
                            </div>
                            <!-- TITLE END -->


                            <!-- LISTING WITH NUMBERING & ALPHA -->
                            <div class="section-content">
                                <ul class="list-simple p-l15">
                                    <li>Creating a sustainable future through building preservation, green architecture.</li>
                                    <li>Green architecture,and smart design.Creating quality urban lifestyles.
                                        <ul>
                                            <li>Listen better. Plan better. Build better.</li>
                                            <li>Listen better. Plan better. Build better.</li>
                                        </ul>
                                    </li>
                                    <li>Sustainable future through building creating quality urban lifestyles.</li>
                                    <li>Preservation, green architecture, and smart quality urban lifestyles.</li>
                                </ul>
                            </div>
                        </div>
                        
                        <div class="m-b50">

                            <!-- TITLE START -->
                            <div class="section-head">
                                <div class="mt-separator-outer separator-left">
                                    <div class="mt-separator">
                                        <h2 class="text-black text-uppercase sep-line-one "><span
                                                class="font-weight-300 text-primary">List</span> Unstyled</h2>
                                    </div>
                                </div>
                            </div>
                            <!-- TITLE END -->


                            <!-- LISTING WITH NUMBERING & ALPHA -->
                            <div class="section-content">
                                <ul class="list-unstyled list-simple">
                                    <li>Creating a sustainable future through building preservation.</li>
                                    <li>Future through building preservation, green architecture,and smart design
                                        <ul>
                                            <li>Listen better. Plan better. Build better.</li>
                                            <li>Listen better. Plan better. Build better.</li>
                                        </ul>
                                    </li>
                                    <li>Creating a sustainable future through building preservation.</li>
                                    <li>Reservation, green architecture, and smart quality urban lifestyles.</li>
                                </ul>
                            </div>
                        </div>

                        <div class="m-b50">
                            <!-- TITLE START -->
                            <div class="section-head">
                                <div class="mt-separator-outer separator-left">
                                    <div class="mt-separator">
                                        <h2 class="text-black text-uppercase sep-line-one "><span
                                                class="font-weight-300 text-primary">List</span> Ordered</h2>
                                    </div>
                                </div>
                            </div>
                            <!-- TITLE END -->


                            <!-- LISTING WITH NUMBERING & ALPHA -->
                            <div class="section-content">
                                <div class="m-b50">
                                    <ol type="1" class="m-l15 list-simple">
                                        <li>Creating a sustainable future through building preservation</li>
                                        <li>Architecture, and smart design.Creating quality urban lifestyles.</li>
                                    </ol>
                                    <ol type="A" class="m-l15 list-simple">
                                        <li>Future through building preservation, green</li>
                                        <li>Aorem ipsum dolor sit amet</li>
                                    </ol>
                                </div>

                                <div class="m-b50">
                                    <ol type="i" class="m-l15 list-simple">
                                        <li>Creating a sustainable future through building preservation..</li>
                                        <li>Aorem ipsum dolor sit amet</li>
                                    </ol>
                                    <ol type="I" class="m-l15 list-simple">
                                        <li>Creating a sustainable future reating quality urban lifestyles.</li>
                                        <li>Aorem ipsum dolor sit amet</li>
                                    </ol>
                                </div>
                            </div>

                        </div>

                        <div class="m-b0">

                            <!-- TITLE START -->
                            <div class="section-head">
                                <div class="mt-separator-outer separator-left">
                                    <div class="mt-separator">
                                        <h2 class="text-black text-uppercase sep-line-one "><span
                                                class="font-weight-300 text-primary">List</span> Numbering &amp; Alfha
                                        </h2>
                                    </div>
                                </div>
                            </div>
                            <!-- TITLE END -->
                            <!-- LISTING WITH NUMBERING & ALPHA -->
                            <div class="section-content">
                                <div class="m-b50">
                                    <ol class="list-num-count upper-alpha list-outline-none">
                                        <li>Creating a sustainable future through building preservation.</li>
                                        <li>Architecture, and smart design.Creating quality urban lifestyles.</li>
                                    </ol>
                                    <ol class="list-num-count upper-alpha list-outline">
                                        <li>Creating a sustainable future through building preservation.</li>
                                        <li>Architecture, and smart design.Creating quality urban lifestyles.</li>
                                    </ol>
                                </div>
                                <div class="m-b0">
                                    <ol class="list-num-count">
                                        <li>Creating a sustainable future through building preservation.</li>
                                        <li>Architecture, and smart design.Creating quality urban lifestyles.</li>
                                    </ol>
                                    <ol class="list-num-count lower-alpha">
                                        <li>Creating a sustainable future through building preservation.</li>
                                        <li>Architecture, and smart design.Creating quality urban lifestyles.</li>
                                    </ol>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>

    <!-- CONTENT END -->

    <!-- FOOTER START -->
    <app-footer></app-footer>
    <!-- FOOTER END -->

</div>

<app-loader></app-loader>