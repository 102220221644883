<div class="page-wraper">  
      	
    <!-- HEADER START -->
    <app-header></app-header>
    <!-- HEADER END -->
    
    <!-- CONTENT START -->
    <div class="page-content">
    
        <!-- INNER PAGE BANNER -->
        <app-banner [data]="banner"></app-banner>
        <!-- INNER PAGE BANNER END -->
         
        <!-- SECTION CONTENTG START -->
        <div class="section-full p-tb80">  
            <div class="container">
                <div class="section-content">
                    <div class="page-notfound text-center">
                        <strong>Page Not Found</strong>
                        <span class="title">404</span>
                        <p>The webpage you are looking for is not here!</p>
                        <a routerLink="/index" title="Back to home" class="site-button btn-effect">Back to home</a>
                    </div>
                </div>
            </div>
        </div>
        <!-- SECTION CONTENT END -->
        
    </div>
    <!-- CONTENT END -->

    <!-- FOOTER START -->
    <app-footer></app-footer>
    <!-- FOOTER END -->
    
</div>

<app-loader></app-loader>