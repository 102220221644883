import { ChangeDetectorRef, Component, HostListener, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})
export class AboutComponent implements OnInit {

  hideAboutImage = false;
  @Input() data: any;

  name: string;
  email: string;
  subject: string;
  message: string;
  ExperienceYears: number;
  aboutImageURL: string;

  @HostListener('window:resize', ['$event'])

  onResize(): void {
    this.hideAboutImage = window.innerWidth <= 720;
  }

  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
  ) { }

  ngOnInit(): void {
    if (this.data) {
      this._changeDetectorRef.detectChanges();
      if (this.data.slides && this.data.slides.length > 0){
        this.aboutImageURL = this.data.slides[0];
      }
      else{
        this.hideAboutImage = true;
      }
    }
    this.hideAboutImage = window.innerWidth <= 720;
    const startDate = new Date(this.data.experience, 0);
    const DifMs = Date.now() - startDate.getTime();
    const DifDate = new Date(DifMs);
    this.ExperienceYears = Math.abs(DifDate.getUTCFullYear() - 1970);
  }

}
