import { AfterViewInit, Component, EventEmitter, HostListener, Input, Output, OnInit } from '@angular/core';

declare var jQuery: any;
declare var load_rev_slider_1: any;

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.css']
})
export class SliderComponent implements AfterViewInit, OnInit {
  @Input() data: any;
  @Output() scrollEvent = new EventEmitter<string>();

  mobileView = false;

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.mobileView = window.innerWidth <= 600;
  }

  constructor() { }

  emitDestination(dest) {
    this.scrollEvent.emit(dest);
  }

  ngOnInit(): void {
    this.mobileView = window.innerWidth <= 600;
  }

  ngAfterViewInit(): void {
    (function ($) {
      setTimeout(function () {
        load_rev_slider_1();
      }, 100);
    })(jQuery);
  }
}
