<div *ngIf="isLoaded" class="page-wraper">

    <!-- HEADER START -->

    <app-header id="Home" [data]="contact_us" (scrollEvent)="scrollToElement($event)"></app-header>

    <!-- HEADER END -->

    <!-- CONTENT START -->
    <div class="page-content">

        <!-- SLIDER START -->
        <app-slider *ngIf="oList_Home_Info" (scrollEvent)="scrollToElement($event)" [data]="oList_Home_Info">
        </app-slider>
        <!-- SLIDER END -->

        <!-- ABOUT COMPANY SECTION START -->
        <app-about *ngIf="about" id="about" [data]="about"></app-about>
        <!-- ABOUT COMPANY SECTION END -->

        <!-- OUR SERVICES START -->
        <app-services id="services" [data]="oList_Service" #services></app-services>
        <!-- OUR SERVICES  END -->

        <!-- OUR MISSION START -->
        <app-mission *ngIf="oOur_mission" id="mission" [data]="oOur_mission"></app-mission>
        <!-- OUR MISSION  END -->

        <!-- OUR VISION START -->
        <app-vision *ngIf="oOur_mission" id="vision" [data]="oOur_mission"></app-vision>
        <!-- OUR VISION START -->

        <app-call-us *ngIf="contact_us" [data]="contact_us"></app-call-us>
    </div>
    <!-- CONTENT END -->

    <!-- FOOTER START -->
    <app-footer *ngIf="contact_us" (scrollEvent)="scrollToElement($event)" [data]="contact_us"></app-footer>
    <!-- FOOTER END -->

</div>

<app-loader></app-loader>