import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-our-value',
  templateUrl: './our-value.component.html',
  styleUrls: ['./our-value.component.css']
})
export class OurValueComponent {

  @Input() data: any;
  Experience: number;


}
