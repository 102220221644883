<div class="testimonial-2 m-a30 hover-animation-1">
    <div class=" block-shadow bg-white p-a30">
        <div class="testimonial-detail clearfix">
            <div class="testimonial-pic radius shadow "><img src="{{data.image}}" width="100" height="100" alt=""></div>
            <h4 class="testimonial-name m-b5">{{data.name}} -</h4>
            <span class="testimonial-position">{{data.designation}}</span>
        </div>                                    
        <div class="testimonial-text">
            <span class="fa fa-quote-right"></span>
            <p> {{data.quote}}</p>
        </div>
    </div>
</div>