import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { GoogleMapsModule } from '@angular/google-maps';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoaderDirective } from './core/Directives/loader.directive';
import { CommonService } from './core/Services/common.service';
import { Proxy } from './core/Services/proxy.service';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SafePipe } from './core/pipes/safe.pipe';
import { Error404Component } from './pages/error404/error404.component';
import { AboutComponent } from './pages/home/components/about/about.component';
import { CallUsComponent } from './pages/home/components/call-us/call-us.component';
import { MissionComponent } from './pages/home/components/mission/mission.component';
import { OurValueComponent } from './pages/home/components/our-value/our-value.component';
import { ServicesComponent } from './pages/home/components/services/services.component';
import { VisionComponent } from './pages/home/components/vision/vision.component';
import { HomeComponent } from './pages/home/home.component';
import { AccordianComponent } from './pages/shortcodes/accordian/accordian.component';
import { ButtonsComponent } from './pages/shortcodes/buttons/buttons.component';
import { IconBoxComponent } from './pages/shortcodes/icon/icon-box/icon-box.component';
import { IconFontComponent } from './pages/shortcodes/icon/icon-font/icon-font.component';
import { ListGroupComponent } from './pages/shortcodes/list-group/list-group.component';
import { ModalPopupComponent } from './pages/shortcodes/modal-popup/modal-popup.component';
import { TablesComponent } from './pages/shortcodes/tables/tables.component';
import { TabsComponent } from './pages/shortcodes/tabs/tabs.component';
import { VideosComponent } from './pages/shortcodes/videos/videos.component';
import { BannerComponent } from './page_sections/banner/banner.component';
import { FooterComponent } from './page_sections/footer/footer.component';
import { HeaderComponent } from './page_sections/header/header.component';
import { LeftSidebar1Component } from './page_sections/shortcodes/left-sidebar1/left-sidebar1.component';
import { AccordianBox1Component } from './section_elements/boxes/accordian-box1/accordian-box1.component';
import { BlogBox1Component } from './section_elements/boxes/blog-box1/blog-box1.component';
import { BlogBox2Component } from './section_elements/boxes/blog-box2/blog-box2.component';
import { BlogBox3Component } from './section_elements/boxes/blog-box3/blog-box3.component';
import { BlogBox4Component } from './section_elements/boxes/blog-box4/blog-box4.component';
import { CommentBox1Component } from './section_elements/boxes/comment-box1/comment-box1.component';
import { GridBox1Component } from './section_elements/boxes/grid-box1/grid-box1.component';
import { GridBox2Component } from './section_elements/boxes/grid-box2/grid-box2.component';
import { GridBox3Component } from './section_elements/boxes/grid-box3/grid-box3.component';
import { GridBox4Component } from './section_elements/boxes/grid-box4/grid-box4.component';
import { PriceBox1Component } from './section_elements/boxes/price-box1/price-box1.component';
import { ProjectBox1Component } from './section_elements/boxes/project-box1/project-box1.component';
import { ProjectBox2Component } from './section_elements/boxes/project-box2/project-box2.component';
import { ServiceBox1Component } from './section_elements/boxes/service-box1/service-box1.component';
import { ServiceBox2Component } from './section_elements/boxes/service-box2/service-box2.component';
import { ServiceBox3Component } from './section_elements/boxes/service-box3/service-box3.component';
import { SolutionBox1Component } from './section_elements/boxes/solution-box1/solution-box1.component';
import { StoryBox1Component } from './section_elements/boxes/story-box1/story-box1.component';
import { TeamBox1Component } from './section_elements/boxes/team-box1/team-box1.component';
import { TeamBox2Component } from './section_elements/boxes/team-box2/team-box2.component';
import { TestimonialBox1Component } from './section_elements/boxes/testimonial-box1/testimonial-box1.component';
import { TestimonialBox2Component } from './section_elements/boxes/testimonial-box2/testimonial-box2.component';
import { LoaderComponent } from './section_elements/loader/loader.component';
import { SliderComponent } from './pages/home/components/slider/slider.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    OurValueComponent,
    MissionComponent,
    ServicesComponent,
    CallUsComponent,
    SliderComponent,
    VisionComponent,
    AboutComponent,
    Error404Component,
    AccordianComponent,
    ButtonsComponent,
    ListGroupComponent,
    ModalPopupComponent,
    TablesComponent,
    TabsComponent,
    VideosComponent,
    LeftSidebar1Component,
    IconBoxComponent,
    IconFontComponent,
    GridBox1Component,
    ServiceBox1Component,
    GridBox2Component,
    BlogBox1Component,
    TestimonialBox1Component,
    GridBox3Component,
    ServiceBox2Component,
    TeamBox1Component,
    PriceBox1Component,
    TestimonialBox2Component,
    ServiceBox3Component,
    StoryBox1Component,
    AccordianBox1Component,
    SolutionBox1Component,
    GridBox4Component,
    TeamBox2Component,
    BlogBox2Component,
    BlogBox3Component,
    BlogBox4Component,
    ProjectBox1Component,
    ProjectBox2Component,
    CommentBox1Component,
    LoaderDirective,
    LoaderComponent,
    SafePipe,
    BannerComponent,
    FooterComponent,
    HeaderComponent,
    HomeComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    GoogleMapsModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule
  ],
  providers: [Proxy, CommonService],
  bootstrap: [AppComponent]
})
export class AppModule { }
