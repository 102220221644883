<div class="col-sm-12 col-lg-offset-2 col-lg-8 col-md-offset-2 col-md-8">
    <div class="testimonial-1 testimonial-bg m-a30 hover-animation-1 text-center text-white">
        <div class="testimonial-detail clearfix">
            <div class="testimonial-pic radius shadow "><img src="{{data.image}}"
                    width="100" height="100" alt=""></div>
        </div>
        <div class="testimonial-text">
            <span class="fa fa-quote-right"></span>
            <p> {{data.quote}}</p>
        </div>
        <div class="testimonial-detail clearfix">
            <h4 class="testimonial-name m-b5">{{data.name}} -</h4>
            <span class="testimonial-position">{{data.designation}}</span>
        </div>
    </div>
</div>