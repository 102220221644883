<div class="col-md-4 col-sm-6">
    <div class="our-story-pic-block">
        <div class="mt-media our-story-pic">
            <img src="{{data.image}}" alt="">
        </div>
    </div>
</div>
<div class="col-md-8 col-sm-6">
    <div class="mt-box our-story-detail bg-moving bg-cover"
        style="background-image:url(assets/images/background/line.png)">
        <h4 class="text-uppercase m-b20">{{data.year}}</h4>
        <h4 class="m-b20">{{data.title}}</h4>
        <p>{{data.description}} </p>
    </div>
</div>