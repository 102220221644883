<div class="blog-post blog-grid date-style-2 bg-white">
            
    <div class="mt-post-media mt-img-effect zoom-slow">
        <a routerLink="/post-image"><img src="{{data.image}}" alt=""></a>
    </div>
    
    <div class="mt-post-info {{textleft == '1' ? 'p-t30' : 'p-a30'}}">

        <div class="mt-post-title ">
            <h4 class="post-title"><a routerLink="/post-image">{{data.title}}</a></h4>
        </div>
        
        <div class="mt-post-meta ">
            <ul>
                <li class="post-date"> <i class="fa fa-calendar"></i><strong>{{data.date}}</strong> <span>{{data.month}}</span> </li>
                <li class="post-author"><i class="fa fa-user"></i><a routerLink="/post-image">By <span>{{data.author}}</span></a> </li>
                <li class="post-comment"><i class="fa fa-comments"></i> <a routerLink="/post-image">{{data.comments}} Comments</a> </li>
            </ul>
        </div>
        
        <div class="mt-post-text">
            <p>{{data.description}}</p> 
        </div>
        
        <div class="clearfix">
            <div class="mt-post-readmore pull-left">
                 <a routerLink="/post-image" title="READ MORE" rel="bookmark" class="site-button-link">Read More<i class="fa fa-angle-right arrow-animation"></i></a>
            </div>
            <div class="widget_social_inks pull-right">
                <ul class="social-icons social-radius social-dark m-b0">
                    <li><a href="https://www.facebook.com" class="fa fa-facebook"></a></li>
                    <li><a href="https://twitter.com" class="fa fa-twitter"></a></li>
                    <li><a href="https://rss.com" class="fa fa-rss"></a></li>
                    <li><a href="https://www.youtube.com" class="fa fa-youtube"></a></li>
                    <li><a href="https://www.instagram.com" class="fa fa-instagram"></a></li>
                </ul>
           </div>
        </div>
        
    </div>
    
</div>